module.exports = {
  validateCPF (strCPF) {
    let sum
    let rest
    sum = 0

    if (strCPF === '00000000000' ||
        strCPF === '11111111111' ||
        strCPF === '22222222222' ||
        strCPF === '33333333333' ||
        strCPF === '44444444444' ||
        strCPF === '55555555555' ||
        strCPF === '66666666666' ||
        strCPF === '77777777777' ||
        strCPF === '88888888888' ||
        strCPF === '99999999999') { return false }
    for (let i = 1; i <= 9; i++) { sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i) }
    rest = (sum * 10) % 11
    if ((rest === 10) || (rest === 11)) { rest = 0 }
    if (rest !== parseInt(strCPF.substring(9, 10))) { return false }
    sum = 0
    for (let i = 1; i <= 10; i++) { sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i) }
    rest = (sum * 10) % 11
    if ((rest === 10) || (rest === 11)) { rest = 0 }
    if (rest !== parseInt(strCPF.substring(10, 11))) { return false }
    return true
  },

  validateCNPJ (cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '')

    if (cnpj === '') return false

    if (cnpj.length !== 14) return false

    if (cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999') return false

    let size = cnpj.length - 2
    let numbers = cnpj.substring(0, size)
    let digits = cnpj.substring(size)
    let sum = 0
    let pos = size - 7
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--
      if (pos < 2) pos = 9
    }

    let result = sum % 11 < 2 ? 0 : 11 - sum % 11
    if (result !== Number(digits.charAt(0))) return false

    size = size + 1
    numbers = cnpj.substring(0, size)
    sum = 0
    pos = size - 7
    for (let x = size; x >= 1; x--) {
      sum += numbers.charAt(size - x) * pos--
      if (pos < 2) pos = 9
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11
    if (result !== Number(digits.charAt(1))) return false

    return true
  },

  closeSidebarOverlay () {
    const sidebar = document.getElementById('mySidebar')
    const logoCenterHM = document.getElementById('logo-center').classList
    if (sidebar.classList !== null) {
      if (window.matchMedia('(max-width: 992px)').matches && sidebar.classList.contains('sidebar-open')) {
        sidebar.classList.remove('sidebar-open')
        sidebar.classList.add('sidebar-closed')
        sidebar.classList.add('sidebar-collapse')
        logoCenterHM.remove('logo-center-desactive')
        logoCenterHM.add('logo-center-active')
      }
    }
  }
}
