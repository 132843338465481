<template>
  <div
    class="modal fade"
    id="modalAcceptTerm"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAcceptTermLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="modalAcceptTermLabel">
            Contrato de prestação de serviço
          </h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="scroll-term">
            <p>
              CONTRATANTE {{ first_name.toUpperCase() }}
              {{ last_name.toUpperCase() }}, BRASILEIRO(A), Estado civil
              {{ formatMaritial(marital_status) }}, Profissão
              {{ occupation ? occupation.toUpperCase() : '-' }}, inscrito no
              CPF sob nº {{ cpf_cnpj ? cpf_cnpj : '-' }} e RG sob o nº
              {{ doc_id ? doc_id : '-' }} residente na R: {{ street }}, N°:
              {{ number ? number : '-' }}, Bairro: {{ neighborhood }}, Município:
              {{ city }}/{{ state }}, CEP: {{ cep }}.
            </p>
            <p>
              Telefone: {{ phone_1 ? phone_1 : '-' }} /
              {{ phone_2 ? phone_2 : '-' }}
            </p>
            <p>E-mail: {{ email ? email : '-' }}</p>
            <p>
              Representado por:
              {{ legal_first_name ? legal_first_name.toUpperCase() : '-' }}
              {{ legal_last_name ? legal_last_name.toUpperCase() : '-' }},
              CPF: {{ legal_cpf_cnpj ? legal_cpf_cnpj : '-' }}
            </p>
            <p>
              CONTRATADA: HELP ISENÇÕES LTDA, pessoa jurídica de
              direito privado, com sede social na Cidade de Cachoeiro do
              Itapemirim, Espírito Santo, na Av. Francisco Lacerda de Aguiar, nº
              177, Ed. Arpoador sala 507, Bairro Gilberto Machado, CEP
              29303-387, inscrita no Cadastro Nacional de Pessoas Juridicas sob
              o nº 47.396.002/0001-05.
            </p>
            <p>Convencionam:</p>
            <p>
              1. O contratado prestará seus serviços profissionais na defesa dos
              direitos do contratante para executar o processo administrativo
              junto aos órgãos competentes pertinente ao Tratamento Tributário
              Diferenciado, tendo em vista adquirir o direito à isenção do
              imposto [ ] IPI [ ] IOF*, na compra do veículo 0km pretendido pelo
              contratante, de acordo com previsão. Deficiência: [
              {{ disability_type === 1 ? 'X' : '' }} ] FÍSICA [
              {{ disability_type === 2 ? 'X' : '' }} ] MENTAL [
              {{ disability_type === 4 ? 'X' : '' }} ] AUTISMO [
              {{ disability_type === 3 ? 'X' : '' }} ] VISUAL [
              {{ disability_type === 5 ? 'X' : '' }} ] AUDITIVO Condutor: [
              {{ driver === true ? 'X' : '' }} ] SIM [
              {{ driver === false ? 'X' : '' }} ] NÃO * Pode ser utilizada uma
              única vez e é apenas para condutor.
            </p>
            <p>
              2. Os honorários são R$ 1.500,00 (MIL E QUINHENTOS REAIS).
              Independente da concessionária escolhida – o pagamento será feito:
              [ ] cartão, [ ] pix: 47.396.002/0001-05 ou [ ] Boleto Bancário a
              vista.
            </p>
            <p>
              3. A partir da emissão das guias de autorização de isenção de IPI
              e/ou IOF, a entrega dos documentos citados será efetuada ao
              contratante, somente mediante o pagamento de possíveis pendências
              e dentro do prazo máximo de 60 dias a partir da ciência do
              cliente.
            </p>
            <p>
              4. O Contratante foi amplamente esclarecido sobre os prazos de
              validade para fruição dos benefícios fiscais discorridos neste
              contrato. São eles:
            </p>
            <p>
              4.1. Autorização de isenção de IPI: 270 (duzentos e setenta) dias.
            </p>
            <p>
              4.2. Autorização de isenção de IOF: 270 (duzentos e setenta) dias.
            </p>
            <p>
              5. A não utilização do benefício dentro do prazo gera a sua
              extinção, não havendo possibilidade para renovação. No entanto, é
              permitido que seja feito um novo pedido a partir de um novo
              processo administrativo com a sua devida e completa instrução e
              pagamento dos custos.
            </p>
            <p>
              6. O Contratado esclareceu ao Contratante sobre o fim ao qual se
              destina o benefício fiscal pretendido por intermédio da prestação
              de serviço objeto deste contrato, exatamente de acordo com a
              legislação conexa, restando o Contratado isento de qualquer
              responsabilidade na hipótese de o Contratante proceder com má fé
              ou desvio de finalidade do já mencionado benefício.
            </p>
            <p>
              7. O CONTRATANTE está informado que trata-se de contrato de
              prestação de serviços, sendo vedado ao CONTRATADO garantir que os
              benefícios serão concedidos em favor do CONTRATANTE, uma vez que
              se trata de processo administrativo e caberá ao fiscal fazendário
              deliberar e decidir. Em caso de indeferimento, o CONTRATANTE não
              será ressarcido de nenhum valor pago.
            </p>
            <p>
              7.1. Da mesma forma o CONTRATANTE não será ressarcido de nenhum
              valor pago em caso de desistência por parte do CONTRATANTE.
            </p>
            <p>
              8. É firmado o presente instrumento particular, elegendo-se o foro
              da cidade de Cachoeiro de Itapemirim para dirimir quaisquer
              controvérsias.
            </p>
            <p>É firmado o presente contrato.</p>

            <h6>PARA O PROCESSO DE CONDUTOR</h6>

            <p>
              1. Faz-se saber que o processo para a isenção do IPI em benesse de
              PCD condutor somente é passível de protocolo caso o beneficiário
              possua CNH válida com as observações nos termos da Junta Médica
              Especial do DETRAN.
            </p>
            <p>
              2. Caso a CNH do beneficiário não contenha as observações acima
              mencionadas, o CONTRATANTE deverá providenciar o documento junto
              ao DETRAN/CIRETRAN para a renovação da sua CNH.
            </p>
            <p>
              3. Nos casos em que a data do acometimento da deficiência do
              beneficiário condutor for mais antiga que a data da emissão da sua
              CNH válida, este poderá encontrar dificuldades para a renovação da
              própria, nos termos da Junta Médica Especial, ou ainda, passar por
              Junta Médica Especial designada pelo DETRAN em município
              divergente daquele onde reside.
            </p>
            <p>
              4. Faz-se necessário esclarecer que o serviço de regularização da
              CNH é inerente ao processo de isenção de IPI e IOF nos casos em
              que ainda não constem na CNH do Contratante as observações
              referentes às restrições para que a Pessoa com Deficiência dirija
              em segurança.
            </p>
            <p>
              5. O serviço de regularização da CNH acima mencionado é realizado
              junto ao CIRETRAN, o qual designará perícia médica para avaliar as
              aptidões físicas do condutor e concluirá considerando-o “apto”,
              “apto com restrições”, “inapto” ou “inapto temporário”. Caso a
              Junta Médica Especial não considere o condutor “apto com
              restrições”, poderá ser solicitada nova perícia a ser realizada
              por uma nova Junta Médica Especial em outro município da região.
              Persistindo qualquer resultado diferente de “apto com restrições”,
              poderá ser apresentado recurso administrativo ao CETRAN a fim de
              alterar o resultado.
            </p>
            <p>
              6. A cada perícia é cobrada uma taxa que deverá ser liquidada pelo
              Contratante. Na hipótese de, após as três tentativas, o condutor
              continuar com resultado diferente de “apto com restrições”,
              encerrar-se-á o processo de isenção sem qualquer restituição de
              pagamento ao Contratante, haja vista o serviço ter sido prestado
              da melhor maneira em busca dos direitos do Contrato.
            </p>
            <p>
              7. Para fins de interpretação do item 1. e seguintes, não se
              considera restrição para a finalidade do presente contrato, a
              restrição “A” referente ao uso de lentes corretivas. Somente
              surtirão efeitos as restrições referentes à deficiência física.
            </p>
            <p>
              8. O Contratado esclareceu ao Contratante sobre o fim ao qual se
              destina o benefício fiscal pretendido por intermédio da prestação
              de serviço objeto deste contrato, exatamente de acordo com a
              legislação conexa, restando o Contratado isento de qualquer
              responsabilidade na hipótese de o Contratante proceder com má fé
              ou desvio de finalidade do já mencionado benefício.
            </p>

            <p>
              9. Todo o processo de alteração de CNH para inclusão da restrição
              e por conta do contratante, ficando expressamente esclarecido que
              a nossa contratação e especificamente para elaborar o processo de
              isenção na Receita Federal.
            </p>
          </div>

          <br />

          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="accept"
                    :value="true"
                    v-model="accept"
                  />
                  <label class="form-check-label"
                    >Eu aceito este contrato</label
                  >
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="accept"
                    :value="false"
                    v-model="accept"
                  />
                  <label class="form-check-label"
                    >Eu não aceito este contrato</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-success"
            :disabled="!accept"
            @click="save()"
            data-toggle="modal"
            data-target="#modalAcceptTerm"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      accept: null
    }
  },
  methods: {
    save () {
      this.$emit('save')
    },
    formatMaritial (data) {
      const map = {
        1: 'CASADO(a)',
        2: 'SOLTEIRO(a)',
        3: 'DIVORCIADO(a)',
        4: 'VIÚVO(a)'
      }

      return map[data]
    }
  },
  props: {
    first_name: {
      type: String,
      default: ''
    },
    last_name: {
      type: String,
      default: ''
    },
    marital_status: {
      type: Number,
      default: null
    },
    occupation: {
      type: String,
      default: ''
    },
    cpf_cnpj: {
      type: String,
      default: ''
    },
    doc_id: {
      type: String,
      default: ''
    },
    street: {
      type: String,
      default: ''
    },
    number: {
      type: String,
      default: ''
    },
    neighborhood: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: ''
    },
    cep: {
      type: String,
      default: ''
    },
    phone_1: {
      type: String,
      default: ''
    },
    phone_2: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    legal_first_name: {
      type: String,
      default: ''
    },
    legal_last_name: {
      type: String,
      default: ''
    },
    legal_cpf_cnpj: {
      type: String,
      default: ''
    },
    disability_type: {
      type: Number,
      default: null
    },
    driver: {
      type: Boolean,
      default: null
    }
  }
}
</script>

<style scoped>
#modalAcceptTerm {
  z-index: 99999;
}

.scroll-term {
  height: 27em;
  overflow: scroll;
  border: 1px solid #d4d7db;
  padding: 13px;
}
</style>
