<template>
  <body class="register-page">
    <div class="register-box">
      <img class="login-logo" src="@/assets/img/logo-main.webp" />
      <div class="card">
        <div class="card-body register-card-body">
          <p class="register-box-msg">Registrar novo usuário</p>

          <div
            class="alert alert-danger text-center"
            role="alert"
            v-if="errorPassword"
          >
            Senhas são diferentes
          </div>

          <div class="alert alert-danger text-center" role="alert" v-if="error">
            Algo deu errado, entre em contato
          </div>

          <div
            class="alert alert-danger text-center"
            role="alert"
            v-if="errorDuplicated"
          >
            Seu usuário já está sendo utilizado, tente utilizar outro usuário ou
            e-mail ou recuperar seus dados
          </div>

          <div
            class="alert alert-success text-center"
            role="alert"
            v-if="success"
          >
            Um e-mail de confirmação foi enviado para o e-mail informado no
            cadastro
          </div>

          <Loader v-if="!render" />

          <form v-if="render && !success" autocomplete="off">
            <div class="input-group mb-3 has-validation">
              <input
                type="text"
                class="form-control"
                placeholder="Nome"
                v-model="dataRegister.first_name"
                required
              />
              <div class="invalid-feedback">
                Please choose a username.
              </div>
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-user"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Sobrenome"
                v-model="dataRegister.last_name"
                required
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-user"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                type="email"
                class="form-control"
                placeholder="E-mail"
                v-model="dataRegister.email"
                required
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                :type="password"
                class="form-control"
                placeholder="Senha"
                v-model="dataRegister.password"
                required
              />
              <div class="input-group-append">
                <div class="input-group-text btn-eye" @click="showPassword()">
                  <span
                    class="fa"
                    :class="{
                      'fa-eye': password == 'password',
                      'fa-eye-slash': password == 'text'
                    }"
                  ></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                v-bind:type="repeatPassword"
                class="form-control"
                placeholder="Repetir senha"
                v-model="dataRegister.repeat_password"
                required
              />
              <div class="input-group-append">
                <div
                  class="input-group-text btn-eye"
                  @click="showRepeatPassword()"
                >
                  <span
                    class="fa"
                    :class="{
                      'fa-eye': repeatPassword == 'password',
                      'fa-eye-slash': repeatPassword == 'text'
                    }"
                  ></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button
                  type="button"
                  class="btn btn-success btn-block"
                  @click="register()"
                  :disabled="
                    !dataRegister.first_name ||
                    !dataRegister.last_name ||
                    !dataRegister.email ||
                    !dataRegister.password ||
                    !dataRegister.repeat_password
                  "
                >
                  Registrar
                </button>
              </div>
            </div>
          </form>
          <hr />
          <p class="mb-0">
            <a @click="$router.push('/login')" class="text-center link"
              >Já tenho uma conta</a
            >
          </p>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import axios from 'axios'

import {
  loadRecaptcha,
  catchRecaptchaToken
} from '../utils/recaptchaVerification'

import Loader from '../components/LoaderComponent'

export default {
  name: 'register',
  components: {
    Loader
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: true,
      dataRegister: {
        name: '',
        email: '',
        billing_email: '',
        password: '',
        repeat_password: '',
        cnpj: '',
        recaptchaToken: '',
        recaptchaAction: 'register'
      },
      errorPassword: false,
      error: false,
      errorDuplicated: false,
      success: false,
      password: 'password',
      repeatPassword: 'password'
    }
  },
  mounted () {
    loadRecaptcha()
  },
  methods: {
    register () {
      const self = this

      const processRegister = () => {
        self.errorPassword = false
        self.error = false
        self.errorDuplicated = false
        self.render = false

        if (self.dataRegister.password !== self.dataRegister.repeat_password) {
          self.errorPassword = true
          self.render = true
          return
        }

        axios({
          method: 'post',
          url: process.env.VUE_APP_ROOT_API + '/auth/signup',
          headers: {
            'Content-Type': 'application/json'
          },
          data: self.dataRegister
        })
          .then((_) => {
            self.render = true
            self.success = true
          })
          .catch((err) => {
            self.render = true

            switch (err.response.status) {
              case 409:
                self.errorDuplicated = true
                break
              default:
                self.error = true
                break
            }
          })
      }

      catchRecaptchaToken(self.dataRegister.recaptchaAction).then((token) => {
        self.dataRegister.recaptchaToken = token
        if (self.dataRegister.recaptchaToken) {
          processRegister()
        }
      })
    },
    showPassword () {
      this.password === 'password'
        ? (this.password = 'text')
        : (this.password = 'password')
    },
    showRepeatPassword () {
      this.repeatPassword === 'password'
        ? (this.repeatPassword = 'text')
        : (this.repeatPassword = 'password')
    }
  }
}
</script>

<style scoped>
.register-box {
  text-align: center;
}

.register-box .login-logo {
  width: 150px;
  margin-bottom: 38px;
}

.btn-eye {
  cursor: pointer;
}
</style>
