import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import JsonCSV from 'vue-json-csv'

import JQuery from 'jquery'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/adminlte.css'
import './assets/css/global.css'
import './assets/plugins/fontawesome-free/css/all.css'
import 'bootstrap/dist/js/bootstrap.js'
import moment from 'moment'
import VueCurrencyFilter from 'vue-currency-filter'
import Notifications from 'vue-notification'
import Paginate from 'vuejs-paginate'
import VueTheMask from 'vue-the-mask'
import { closeSidebarOverlay } from './utils/functions'
import 'balloon-css'

Vue.filter('numberBr', function (value) {
  if (!value) return
  if (value[0] === '0') value = value.replace(value[0], '')
  if (value.length === 11) {
    return value.replace(/(\d{2})(\d{5})(\d{4})/,
      function (regex, arg1, arg2, arg3) {
        return '(' + arg1 + ')' + ' ' + arg2 + '-' + arg3
      })
  }
  return value
})

Vue.filter('cpfOrCnpj', function (value) {
  if (!value) return
  if (value.length === 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
      function (regex, arg1, arg2, arg3, arg4) {
        return arg1 + '.' + arg2 + '.' + arg3 + '-' + arg4
      })
  } else {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      function (regex, arg1, arg2, arg3, arg4, arg5) {
        return arg1 + '.' + arg2 + '.' + arg3 + '/' + arg4 + '-' + arg5
      })
  }
})

Vue.filter('dateTimeBr', function (date) {
  moment.locale('pt-BR')
  return moment(date).format('DD/MM/YYYY HH:mm:ss')
})

Vue.filter('formatInvoiceId', function (id) {
  return !id ? '' : ('00000' + id).slice(-6)
})

Vue.prototype.$func = closeSidebarOverlay

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.use(Notifications)
Vue.component('paginate', Paginate)
Vue.use(VueTheMask)
Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false

window.$ = JQuery

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
