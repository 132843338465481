import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Register from '../views/Register.vue'
import ValidadeEmail from '../views/ValidadeEmail.vue'
import RecoveryPassword from '../views/RecoveryPassword.vue'
import NewPassword from '../views/NewPassword.vue'
import Customers from '../views/Customers.vue'
import Users from '../views/Users.vue'
import userData from '../views/UserData.vue'
import Transactions from '../views/Transactions.vue'
import AddTransaction from '../views/AddTransaction.vue'
import Docs from '../views/Docs.vue'
import AddDocs from '../views/AddDocs.vue'
import Defs from '../views/Defs.vue'
import AddDef from '../views/AddDef.vue'
import CustomerData from '../views/CustomerData.vue'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/validade-email',
    name: 'validade-email',
    component: ValidadeEmail
  },
  {
    path: '/recovery-password',
    name: 'recovery-password',
    component: RecoveryPassword
  },
  {
    path: '/new-password',
    name: 'new-password',
    component: NewPassword
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/customer_data',
    name: 'customerData',
    component: CustomerData,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/user_data',
    name: 'userData',
    component: userData,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/add-transaction',
    name: 'AddTransaction',
    component: AddTransaction,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/docs',
    name: 'Docs',
    component: Docs,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/add-docs',
    name: 'AddDocs',
    component: AddDocs,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/defs',
    name: 'Defs',
    component: Defs,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/add-def',
    name: 'AddDef',
    component: AddDef,
    beforeEnter: ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
