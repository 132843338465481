<template>
  <body
    id="mySidebar"
    class="
      sidebar-mini
      layout-fixed layout-navbar-fixed layout-footer-fixed
      sidebar-collapse
      text-sm
    "
    style="height: auto"
  >
    <div class="wrapper">
      <HeaderComponent />
      <SidebarComponent />

      <div id="myWrapper" class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-6 col-sm-6">
                <h1>Usuários</h1>
              </div>
              <div class="col-6 col-sm-6">
                <span class="float-sm-right add-button" v-if="render">
                  <router-link :to="back">
                    <button type="button" class="btn btn-block btn-outline-secondary">
                      Voltar
                    </button>
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    {{
                      !this.$route.query.id ? 'Adicionar' : 'Atualizar'
                    }}
                    usuário
                  </h3>
                </div>

                <div class="card-body">
                  <LoaderComponent v-if="!render" />

                  <div v-else>
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div class="form-group">
                          <label>Nome <span class="flag-required">*</span></label>
                          <input
                            type="text"
                            class="form-control"
                            name="first_name"
                            v-model="data.first_name"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div class="form-group">
                          <label>Sobrenome <span class="flag-required">*</span></label>
                          <input
                            type="text"
                            class="form-control"
                            name="last_name"
                            v-model="data.last_name"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div class="form-group">
                          <label>E-mail <span class="flag-required">*</span></label>
                          <input
                            type="email"
                            class="form-control"
                            name="email"
                            v-model="data.email"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div class="form-group">
                          <label>Password</label>
                          <input
                            type="password"
                            class="form-control"
                            name="password"
                            v-model="data.password"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div class="form-group">
                          <label>Repetir senha</label>
                          <input
                            type="password"
                            class="form-control"
                            name="repeat_password"
                            v-model="data.repeat_password"
                          />
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-3 col-lg-4">
                        <div class="form-group">
                          <label>Tipo <span class="flag-required">*</span></label>
                          <select
                            class="form-control"
                            name="type"
                            v-model="data.type"
                          >
                            <option disabled value="" selected>
                              Selecione uma opção
                            </option>
                            <option value="1">Administrador</option>
                            <option value="0">Comum</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-3 col-lg-4">
                        <div class="form-group">
                          <label>Status <span class="flag-required">*</span></label>
                          <select
                            class="form-control"
                            name="status"
                            v-model="data.status"
                          >
                            <option disabled value="" selected>
                              Selecione uma opção
                            </option>
                            <option value="1">Ativo</option>
                            <option value="0">Inativo</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    :disabled="
                      !data.first_name ||
                      !data.last_name ||
                      !data.email ||
                      !data.password ||
                      !data.repeat_password ||
                      data.type === '' ||
                      !data.status
                    "
                    @click="SendData()"
                  >
                    Salvar
                  </button>
                  &nbsp;
                  <router-link :to="back">
                    <button type="button" class="btn btn-outline-secondary">
                      Cancelar
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div id="sidebar-overlay" @click="callCloseSidebar"></div>
    </div>
  </body>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import store from '../store/index'

import { prepareError } from '../utils/errorHandle.js'

import HeaderComponent from '@/components/HeaderComponent.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  name: 'userData',
  components: {
    HeaderComponent,
    SidebarComponent,
    LoaderComponent
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: true,
      back: '/users',
      data: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        repeat_password: '',
        type: '',
        status: ''
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      this.getUserData(this.$route.query.id)
    }
  },
  methods: {
    resetData () {
      this.data = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        repeat_password: '',
        type: '',
        status: ''
      }
    },

    getUserData (id) {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN

      this.render = false
      axios({
        method: 'get',
        url: this.api + `/user/` + this.$route.query.id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
        .then((response) => {
          this.data = response.data.data
          this.render = true
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    },

    SendData () {
      if (this.data.password !== this.data.repeat_password) {
        Vue.notify({
          title: 'Senha inválida',
          text: 'Senhas não conferem',
          type: 'error'
        })
        return
      }

      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN

      this.render = false
      axios({
        method: this.$route.query.id ? 'put' : 'post',
        url: !this.$route.query.id
          ? this.api + `/user/create`
          : this.api + `/user/update/` + this.$route.query.id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        data: this.data
      })
        .then((_) => {
          this.$router.go(-1)

          Vue.notify({
            title: 'Dados processados',
            text: 'Todos os dados foram processados com sucesso!',
            type: 'success'
          })
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })

      this.resetData()
    },
    callCloseSidebar () {
      this.$func()
    }
  }
}
</script>

<style scoped>
.add-button {
  text-decoration: none;
}

.ban-icon:hover {
  color: #dc3545;
}

.check-icon:hover {
  color: #28a745;
}

.overflow-x {
  overflow-x: auto;
}
</style>
