module.exports = {
  loadRecaptcha () {
    if (!document.getElementById('recaptcha-id')) {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('id', `recaptcha-id`)
      recaptchaScript.setAttribute(
        'src',
        `https://www.google.com/recaptcha/api.js?render=6LcjJqceAAAAAPBtZPV334VEKF9-kG0Gyf3kcvXK`
      )
      document.head.appendChild(recaptchaScript)
    }
  },

  catchRecaptchaToken (action) {
    const promiseToken = new Promise((resolve) => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(`6LcjJqceAAAAAPBtZPV334VEKF9-kG0Gyf3kcvXK`, {
              action: action
            })
            .then((token) => {
              resolve(token)
            })
        })
      }
    })

    return promiseToken
  }
}
