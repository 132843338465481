<template>
  <body class="login-page">
    <div class="login-box">
      <img class="login-logo" src="@/assets/img/logo-main.webp" />
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">
            Entre com os dados para iniciar uma sessão
          </p>
          <div class="alert alert-danger text-center" role="alert" v-if="error">
            Falha ao realizar o acesso. Confira seus dados e tente novamente.
          </div>

          <Loader v-if="!render" />

          <form v-if="render">
            <div class="input-group mb-3">
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                v-model="dataLogin.email"
                v-on:keyup.enter="login()"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                v-bind:type="password"
                class="form-control"
                placeholder="Senha"
                v-model="dataLogin.password"
                v-on:keyup.enter="login()"
              />
              <div class="input-group-append">
                <div class="input-group-text btn-eye" @click="showPassword()">
                  <span
                    class="fa"
                    :class="{
                      'fa-eye': password == 'password',
                      'fa-eye-slash': password == 'text'
                    }"
                  ></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button
                  type="button"
                  class="btn btn-success btn-block"
                  @click="login()"
                >
                  Entrar
                </button>
              </div>
            </div>
            <hr />
            <p class="mb-0 text-center">
              <a
                @click="$router.push('/recovery-password')"
                class="text-center link"
                >Esqueci minha senha</a
              >
            </p>

            <br />

            <p class="mb-0 text-center">
              <a @click="$router.push('/register')" class="text-center link"
                >Registrar novo usuário</a
              >
            </p>
          </form>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import store from '../store/index'
import Loader from '../components/LoaderComponent'
import {
  loadRecaptcha,
  catchRecaptchaToken
} from '../utils/recaptchaVerification'

export default {
  name: 'login',
  components: {
    Loader
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: true,
      dataLogin: {
        email: '',
        password: '',
        recaptchaToken: '',
        recaptchaAction: 'login'
      },
      error: false,
      password: 'password'
    }
  },
  mounted () {
    loadRecaptcha()
  },
  methods: {
    login () {
      this.render = false
      this.error = false
      const self = this

      const processLogin = () => {
        store
          .dispatch('AUTH_REQUEST', self.dataLogin)
          .then(() => {
            self.render = true
            self.$router.push('/')
          })
          .catch((_) => {
            self.render = true
            self.error = true
          })
      }

      catchRecaptchaToken(self.dataLogin.recaptchaAction).then((token) => {
        self.dataLogin.recaptchaToken = token
        if (self.dataLogin.recaptchaToken) {
          processLogin()
        }
      })
    },

    showPassword () {
      this.password === 'password'
        ? (this.password = 'text')
        : (this.password = 'password')
    }
  }
}
</script>

<style scoped>
.login-box {
  text-align: center;
}

.login-box .login-logo {
  width: 150px;
  margin-bottom: 38px;
}

.btn-eye {
  cursor: pointer;
}
</style>
