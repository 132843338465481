<template>
  <div class='loader'>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'loader'
}
</script>

<style scoped>
@keyframes loader {
  50% {
    filter: blur(5px);
    transform: translateY(-10px);
    opacity: 0.3;
  }
}
body, html {
  margin: 0;
}

.loader {
  display: flex;
  justify-content: center;
}

.loader > div {
  height: 5px;
  width: 5px;
  background-color: rgb(15, 1, 1);
  float: left;
  margin: 0 5px 0 0;
  animation: loader 0.7s infinite;
  opacity: 0.5;
  border-radius: 10%;
}

.loader > div:nth-child(1) {
  animation-delay: 0.1s;
}

.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation-delay: 0.3s;
}

.loader > div:nth-child(4) {
  animation-delay: 0.4s;
}

.loader > div:nth-child(5) {
  animation-delay: 0.5s;
}
</style>
