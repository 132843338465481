<template>
  <body
    id="mySidebar"
    class="
      sidebar-mini
      layout-fixed layout-navbar-fixed layout-footer-fixed
      sidebar-collapse
      text-sm
    "
    style="height: auto"
  >
    <div class="wrapper">
      <HeaderComponent />
      <SidebarComponent />

      <div id="myWrapper" class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-6 col-sm-6">
                <h1>Dados pessoais</h1>
              </div>
              <div class="col-6 col-sm-6">
                <span class="float-sm-right add-button" v-if="render">
                  <router-link :to="back">
                    <button
                      type="button"
                      class="btn btn-block btn-outline-secondary"
                    >
                      Voltar
                    </button>
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <form v-on:submit.prevent="onSubmit" novalidate>
                  <div class="card-header">
                    <h3 class="card-title">
                      {{ !this.$route.query.id ? 'Adicionar' : 'Atualizar' }}
                      dados cadastrados
                    </h3>
                  </div>

                  <div class="card-body">
                    <LoaderComponent v-if="!render" />

                    <div v-else>
                      <div v-if="!render">
                        <LoaderComponent />
                      </div>
                      <div v-else>
                        <div class="card card-secondary">
                          <div class="card-header">Usuário</div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12">
                                <label
                                  >Selecione um usuário
                                  <span class="flag-required">*</span></label
                                >
                                <select
                                  class="form-control"
                                  v-bind:class="{
                                    'is-invalid':
                                      userDataValidation.user_id.error
                                  }"
                                  name="user"
                                  v-model="userData.user_id"
                                >
                                  <option disabled value="" selected>
                                    Selecione uma opção
                                  </option>
                                  <option
                                    v-for="user in users"
                                    :value="user.id"
                                    v-bind:key="user.id"
                                  >
                                    {{ user.first_name }}
                                  </option>
                                </select>
                                <div class="invalid-feedback">
                                  {{ userDataValidation.user_id.error_message }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card card-secondary">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="form-group has-validation">
                                  <label
                                    >A PcD é a condutora do veículo?
                                    <span class="flag-required">*</span></label
                                  >
                                  <select
                                    class="form-control"
                                    v-model="userData.driver"
                                    v-bind:class="{
                                      'is-invalid':
                                        userDataValidation.driver.error
                                    }"
                                  >
                                    <option selected="selected" value="">
                                      Selecione uma opção
                                    </option>
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                  </select>
                                  <div class="invalid-feedback">
                                    {{
                                      userDataValidation.driver.error_message
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-sm-12">
                                <div class="form-group has-validation">
                                  <label
                                    >Possui um laudo válido no prazo de 5 anos?
                                    <span class="flag-required">*</span></label
                                  >
                                  <select
                                    class="form-control"
                                    v-model="userData.detran_medical_report"
                                    v-bind:class="{
                                      'is-invalid': userDataValidation.detran_medical_report.error
                                    }"
                                  >
                                    <option selected="selected" value="">
                                      Selecione uma opção
                                    </option>
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                  </select>
                                  <div class="invalid-feedback">
                                    {{ userDataValidation.detran_medical_report.error_message }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="userData.detran_medical_report">
                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <div v-if="userData.medical_report" class="upload-on-database">
                                      <span
                                        class="close-upload"
                                        @click="removeUploadDatabase('medical_report')"
                                      >
                                        <i class="fas fa-times-circle"></i>
                                      </span>
                                      <a :href="userData.medical_report" target="_blank"
                                        >Laudo cadastrado</a
                                      >
                                    </div>
                                    <div v-else>
                                      <div v-if="!rendermedical_report">
                                        <LoaderComponent />
                                      </div>
                                      <div class="custom-file" v-if="rendermedical_report">
                                        <div v-if="medical_report" class="upload-section">
                                          <span
                                            class="close-upload"
                                            @click="removeUpload('medical_report')"
                                          >
                                            <i class="fas fa-times-circle"></i>
                                          </span>
                                          {{ medical_report.name }}
                                        </div>
                                        <div v-else>
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="medical_report"
                                            name="medical_report"
                                            @change="onMedicalReportChange"
                                          />
                                          <label class="custom-file-label" for="customFile"
                                            >Envie um laudo válido</label
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card card-secondary"
                          v-if="userData.driver !== null"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group has-validation">
                                  <label
                                    >Você já comprou um veículo com isenção de
                                    IPI para PcD?
                                    <span class="flag-required">*</span></label
                                  >
                                  <select
                                    class="form-control"
                                    v-model="userData.already_bought_car"
                                    v-bind:class="{
                                      'is-invalid':
                                        userDataValidation.already_bought_car
                                          .error
                                    }"
                                  >
                                    <option selected="selected" value="">
                                      Selecione uma opção
                                    </option>
                                    <option :value="true">Sim</option>
                                    <option :value="false">Não</option>
                                  </select>
                                  <div class="invalid-feedback">
                                    {{
                                      userDataValidation.already_bought_car
                                        .error_message
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                class="col-sm-3"
                                v-if="userData.already_bought_car"
                              >
                                <div class="form-group has-validation">
                                  <label
                                    >Ano de compra
                                    <span class="flag-required">*</span></label
                                  >
                                  <select
                                    class="form-control"
                                    v-model="userData.last_car_year_bought"
                                    @change="onYearChange($event)"
                                    v-bind:class="{
                                      'is-invalid':
                                        userDataValidation.last_car_year_bought
                                          .error
                                    }"
                                  >
                                    <option selected="selected" value="">
                                      Selecione uma opção
                                    </option>
                                    <option
                                      v-for="year in previous_years"
                                      :value="year"
                                      v-bind:key="year"
                                    >
                                      {{ year }}
                                    </option>
                                  </select>
                                  <div class="invalid-feedback">
                                    {{
                                      userDataValidation.last_car_year_bought
                                        .error_message
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                class="col-sm-3"
                                v-if="
                                  userData.last_car_year_bought &&
                                  !message_year_car_bought &&
                                  userData.already_bought_car === true
                                "
                              >
                                <div class="form-group has-validation">
                                  <label
                                    >Data da compra
                                    <span class="flag-required">*</span></label
                                  >
                                  <input
                                    type="date"
                                    name="last_car_date_bought"
                                    class="form-control"
                                    v-model="userData.last_car_date_bought"
                                    v-bind:class="{
                                      'is-invalid':
                                        userDataValidation.last_car_date_bought
                                          .error
                                    }"
                                  />
                                  <div class="invalid-feedback">
                                    {{
                                      userDataValidation.last_car_date_bought
                                        .error_message
                                    }}
                                  </div>
                                </div>
                              </div>

                              <div class="col-sm-12">
                                <div
                                  class="alert alert-warning alert-dismissible"
                                  v-if="
                                    message_year_car_bought &&
                                    userData.already_bought_car
                                  "
                                >
                                  <h5>
                                    <i
                                      class="icon fas fa-exclamation-triangle"
                                    ></i>
                                    Atenção!
                                  </h5>
                                  Você não poderá comprar outro veículo até
                                  concluir o prazo legal de 3 anos!
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card card-secondary"
                          v-if="userData.user_id && userData.driver"
                        >
                          <div class="card-header">Dados de deficiência</div>

                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="form-group has-validation">
                                  <label
                                    >Selecione uma opção
                                    <span class="flag-required">*</span></label
                                  >
                                  <select
                                    class="form-control"
                                    v-bind:class="{
                                      'is-invalid':
                                        userDataValidation.disability_type.error
                                    }"
                                    name="disability_type"
                                    @change="onChangeType($event.target.value)"
                                    v-model="userData.disability_type"
                                  >
                                    <option selected="selected" value="">
                                      Selecione uma opção
                                    </option>
                                    <option :value="1">
                                      Deficiente físico
                                    </option>
                                    <option :value="2">
                                      Deficiente intelectual
                                    </option>
                                    <option :value="3">
                                      Deficiente visual
                                    </option>
                                    <option :value="4">Autismo</option>
                                    <option :value="5">
                                      Deficiente auditivo
                                    </option>
                                  </select>
                                  <div class="invalid-feedback">
                                    {{
                                      userDataValidation.disability_type
                                        .error_message
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="userData.disability_type === 1">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="form-group has-validation">
                                    <label
                                      >Selecione uma opção
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <select
                                      class="form-control"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.disability.error
                                      }"
                                      name="disability"
                                      v-model="userData.disability"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option
                                        v-for="item in disability"
                                        :value="item.id"
                                        v-bind:key="item.id"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.disability
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="userData.disability_type === 2">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="form-group has-validation">
                                    <label
                                      >Diagnosticado antes dos 18 anos?
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <select
                                      class="form-control"
                                      v-model="yearsPlus18"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.yearsPlus18.error
                                      }"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option :value="true">Sim</option>
                                      <option :value="false">Não</option>
                                    </select>
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.yearsPlus18
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="alert alert-warning alert-dismissible"
                                v-if="yearsPlus18 === false"
                              >
                                <h5>
                                  <i
                                    class="icon fas fa-exclamation-triangle"
                                  ></i>
                                  Atenção!
                                </h5>
                                Para prosseguir com o processo de isenção,
                                deficientes intelectuais devem ser
                                diagnosticados antes de 18 anos!
                              </div>

                              <div class="row" v-if="yearsPlus18">
                                <div class="col-sm-12">
                                  <div class="form-group has-validation">
                                    <label
                                      >Selecione uma opção
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <select
                                      class="form-control"
                                      name="disability"
                                      v-model="userData.disability"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.disability.error
                                      }"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option
                                        v-for="item in disability"
                                        :value="item.id"
                                        v-bind:key="item.id"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.disability
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="userData.disability_type === 3">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="form-group">
                                    <label
                                      >Selecione uma opção
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <select
                                      class="form-control has-validation"
                                      name="disability"
                                      v-model="userData.disability"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.disability.error
                                      }"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option
                                        v-for="item in disability"
                                        :value="item.id"
                                        v-bind:key="item.id"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.disability_type
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              v-if="
                                userData.disability_type === 4 ||
                                userData.disability_type === 5
                              "
                            >
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="form-group">
                                    <label
                                      >Selecione uma opção
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <select
                                      class="form-control has-validation"
                                      name="disability"
                                      v-model="userData.disability"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.disability.error
                                      }"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option
                                        v-for="item in disability"
                                        :value="item.id"
                                        v-bind:key="item.id"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.disability
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card card-secondary"
                          v-if="
                            userData.disability_type &&
                            userData.disability &&
                            ((userData.disability_type === 2 && yearsPlus18) ||
                              userData.disability_type !== 2)
                          "
                        >
                          <div class="card-header">Dados do PcD</div>

                          <div class="card-body">
                            <div>
                              <hr />

                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Nome
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="first_name"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.first_name"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.first_name.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.first_name
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Sobrenome
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="last_name"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.last_name"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.last_name.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.last_name
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Nome do pai
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="father_name"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.father_name"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.father_name.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.father_name
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Nome da mãe
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="mother_name"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.mother_name"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.mother_name.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.mother_name
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Telefone fixo</label>
                                    <the-mask
                                      :mask="[
                                        '(##) ####-####',
                                        '(##) #####-####'
                                      ]"
                                      type="tel"
                                      name="phone_1"
                                      class="form-control"
                                      v-model="userData.phone_1"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Celular</label>
                                    <the-mask
                                      :mask="[
                                        '(##) ####-####',
                                        '(##) #####-####'
                                      ]"
                                      type="tel"
                                      name="phone_2"
                                      class="form-control"
                                      v-model="userData.phone_2"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label> Identidade/IE </label>
                                    <input
                                      type="text"
                                      name="doc_id"
                                      class="form-control"
                                      v-model="userData.doc_id"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label> Órgão Expedidor </label>
                                    <input
                                      type="text"
                                      name="doc_id_company"
                                      class="form-control"
                                      v-model="userData.doc_id_company"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label> RG (UF) </label>
                                    <select
                                      class="form-control"
                                      name="doc_id_uf"
                                      v-model="userData.doc_id_uf"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option :value="1">Acre (AC)</option>
                                      <option :value="2">Alagoas (AL)</option>
                                      <option :value="3">Amapá (AP)</option>
                                      <option :value="4">Amazonas (AM)</option>
                                      <option :value="5">Bahia (BA)</option>
                                      <option :value="6">Ceará (CE)</option>
                                      <option :value="7">
                                        Distrito Federal (DF)
                                      </option>
                                      <option :value="8">
                                        Espírito Santo (ES)
                                      </option>
                                      <option :value="9">Goiás (GO)</option>
                                      <option :value="10">Maranhão (MA)</option>
                                      <option :value="11">
                                        Mato Grosso (MT)
                                      </option>
                                      <option :value="12">
                                        Mato Grosso do Sul (MS)
                                      </option>
                                      <option :value="13">
                                        Minas Gerais (MG)
                                      </option>
                                      <option :value="14">Pará (PA)</option>
                                      <option :value="15">Paraíba (PB)</option>
                                      <option :value="16">Paraná (PR)</option>
                                      <option :value="17">
                                        Pernambuco (PE)
                                      </option>
                                      <option :value="18">Piauí (PI)</option>
                                      <option :value="19">
                                        Rio de Janeiro (RJ)
                                      </option>
                                      <option :value="20">
                                        Rio Grande do Norte (RN)
                                      </option>
                                      <option :value="21">
                                        Rio Grande do Sul (RS)
                                      </option>
                                      <option :value="22">Rondônia (RO)</option>
                                      <option :value="23">Roraima (RR)</option>
                                      <option :value="24">
                                        Santa Catarina (SC)
                                      </option>
                                      <option :value="25">
                                        São Paulo (SP)
                                      </option>
                                      <option :value="26">Sergipe (SE)</option>
                                      <option :value="27">
                                        Tocantins (TO)
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group has-validation">
                                    <label
                                      >CPF<span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <the-mask
                                      :mask="['###.###.###-##']"
                                      type="text"
                                      name="cpf_cnpj"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.cpf_cnpj"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.cpf_cnpj.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.cpf_cnpj
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group has-validation">
                                    <label
                                      >Nascimento
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="date"
                                      name="birth"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.birth"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.birth.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.birth.error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group has-validation">
                                    <label
                                      >Nacionalidade
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="birth_country"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.birth_country"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.birth_country.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.birth_country
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label>Título eleitor</label>
                                    <input
                                      type="text"
                                      name="doc"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.doc"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label>Profissão</label>
                                    <input
                                      type="text"
                                      name="occupation"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.occupation"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label
                                      >Estado civil<span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <select
                                      class="form-control"
                                      required="required"
                                      name="marital_status"
                                      v-model="userData.marital_status"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.marital_status
                                            .error
                                      }"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option :value="1">Casado(a)</option>
                                      <option :value="2">Solteiro(a)</option>
                                      <option :value="3">Divorciado(a)</option>
                                      <option :value="4">Viúvo(a)</option>
                                    </select>
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.marital_status
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >E-mail
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="email"
                                      name="email"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.email"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.email.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.email.error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              <div class="row">
                                <div class="col-sm-3">
                                  <div class="form-group has-validation">
                                    <label
                                      >CEP
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="number"
                                      name="cep"
                                      required="required"
                                      class="form-control"
                                      @keyup="getCep()"
                                      v-model="userData.cep"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.cep.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{ userDataValidation.cep.error_message }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Endereço
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="street"
                                      required="required"
                                      class="form-control"
                                      disabled
                                      v-model="userData.street"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.street.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.street.error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-3">
                                  <div class="form-group">
                                    <label>N./Apt.</label>
                                    <input
                                      type="text"
                                      name="number"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.number_address"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group has-validation">
                                    <label
                                      >Bairro
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="neighborhood"
                                      required="required"
                                      class="form-control"
                                      disabled
                                      v-model="userData.neighborhood"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.neighborhood.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.neighborhood
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Cidade
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="city"
                                      required="required"
                                      class="form-control"
                                      disabled
                                      v-model="userData.city"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.city.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.city.error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-2">
                                  <div class="form-group has-validation">
                                    <label
                                      >UF
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="state"
                                      required="required"
                                      class="form-control"
                                      disabled
                                      v-model="userData.state"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.state.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.state.error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card card-secondary"
                          v-if="
                            userData.disability_type &&
                            userData.disability &&
                            ((userData.disability_type === 2 && yearsPlus18) ||
                              userData.disability_type !== 2)
                          "
                        >
                          <div class="card-header">
                            Dados do responsável legal
                          </div>

                          <div class="card-body">
                            <div>
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="form-group">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        @change="setSamePcDData($event)"
                                      />
                                      <label class="form-check-label"
                                        >Utilizar os mesmos dados do PcD</label
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Nome
                                      <span
                                        class="flag-required"
                                        v-if="
                                          userData.disability_type === 2 ||
                                          userData.disability_type === 4
                                        "
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="legal_first_name"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_first_name"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_first_name
                                            .error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_first_name
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Sobrenome
                                      <span
                                        class="flag-required"
                                        v-if="
                                          userData.disability_type === 2 ||
                                          userData.disability_type === 4
                                        "
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="legal_last_name"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_last_name"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_last_name
                                            .error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_last_name
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label> Identidade/IE </label>
                                    <input
                                      type="text"
                                      name="legal_doc_id"
                                      class="form-control"
                                      v-model="userData.legal_doc_id"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label> Órgão Expedidor </label>
                                    <input
                                      type="text"
                                      name="legal_doc_id_company"
                                      class="form-control"
                                      v-model="userData.legal_doc_id_company"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label> RG (UF) </label>
                                    <select
                                      class="form-control"
                                      name="legal_doc_id_uf"
                                      v-model="userData.legal_doc_id_uf"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option :value="1">Acre (AC)</option>
                                      <option :value="2">Alagoas (AL)</option>
                                      <option :value="3">Amapá (AP)</option>
                                      <option :value="4">Amazonas (AM)</option>
                                      <option :value="5">Bahia (BA)</option>
                                      <option :value="6">Ceará (CE)</option>
                                      <option :value="7">
                                        Distrito Federal (DF)
                                      </option>
                                      <option :value="8">
                                        Espírito Santo (ES)
                                      </option>
                                      <option :value="9">Goiás (GO)</option>
                                      <option :value="10">Maranhão (MA)</option>
                                      <option :value="11">
                                        Mato Grosso (MT)
                                      </option>
                                      <option :value="12">
                                        Mato Grosso do Sul (MS)
                                      </option>
                                      <option :value="13">
                                        Minas Gerais (MG)
                                      </option>
                                      <option :value="14">Pará (PA)</option>
                                      <option :value="15">Paraíba (PB)</option>
                                      <option :value="16">Paraná (PR)</option>
                                      <option :value="17">
                                        Pernambuco (PE)
                                      </option>
                                      <option :value="18">Piauí (PI)</option>
                                      <option :value="19">
                                        Rio de Janeiro (RJ)
                                      </option>
                                      <option :value="20">
                                        Rio Grande do Norte (RN)
                                      </option>
                                      <option :value="21">
                                        Rio Grande do Sul (RS)
                                      </option>
                                      <option :value="22">Rondônia (RO)</option>
                                      <option :value="23">Roraima (RR)</option>
                                      <option :value="24">
                                        Santa Catarina (SC)
                                      </option>
                                      <option :value="25">
                                        São Paulo (SP)
                                      </option>
                                      <option :value="26">Sergipe (SE)</option>
                                      <option :value="27">
                                        Tocantins (TO)
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group has-validation">
                                    <label
                                      >CPF<span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <the-mask
                                      :mask="['###.###.###-##']"
                                      type="text"
                                      name="legal_cpf_cnpj"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_cpf_cnpj"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_cpf_cnpj
                                            .error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_cpf_cnpj
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group has-validation">
                                    <label
                                      >Nascimento
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="date"
                                      name="legal_birth"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_birth"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_birth.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_birth
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group has-validation">
                                    <label
                                      >Nacionalidade
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="legal_birth_country"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_birth_country"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_birth_country
                                            .error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_birth_country
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label>Título eleitor</label>
                                    <input
                                      type="text"
                                      name="legal_doc"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_doc"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label>Profissão</label>
                                    <input
                                      type="text"
                                      name="legal_occupation"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_occupation"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label>Estado civil</label>
                                    <select
                                      class="form-control"
                                      name="legal_marital_status"
                                      v-model="userData.legal_marital_status"
                                    >
                                      <option selected="selected" value="">
                                        Selecione uma opção
                                      </option>
                                      <option :value="1">Casado(a)</option>
                                      <option :value="2">Solteiro(a)</option>
                                      <option :value="3">Divorciado(a)</option>
                                      <option :value="4">Viúvo(a)</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Telefone fixo</label>
                                    <the-mask
                                      :mask="[
                                        '(##) ####-####',
                                        '(##) #####-####'
                                      ]"
                                      type="tel"
                                      name="legal_phone_1"
                                      class="form-control"
                                      v-model="userData.legal_phone_1"
                                    />
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label>Celular</label>
                                    <the-mask
                                      :mask="[
                                        '(##) ####-####',
                                        '(##) #####-####'
                                      ]"
                                      type="tel"
                                      name="legal_phone_2"
                                      class="form-control"
                                      v-model="userData.legal_phone_2"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >E-mail
                                      <span
                                        class="flag-required"
                                        v-if="
                                          userData.disability_type === 2 ||
                                          userData.disability_type === 4
                                        "
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="email"
                                      name="legal_email"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_email"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_email.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_email
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="form-group">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        @change="setSameAddress($event)"
                                      />
                                      <label class="form-check-label"
                                        >Utilizar o mesmo endereço do PcD</label
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-3">
                                  <div class="form-group has-validation">
                                    <label
                                      >CEP
                                      <span
                                        class="flag-required"
                                        v-if="
                                          userData.disability_type === 2 ||
                                          userData.disability_type === 4
                                        "
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="number"
                                      name="legal_cep"
                                      required="required"
                                      class="form-control"
                                      @keyup="getCep(true)"
                                      v-model="userData.legal_cep"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_cep.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_cep
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Endereço
                                      <span
                                        class="flag-required"
                                        v-if="
                                          userData.disability_type === 2 ||
                                          userData.disability_type === 4
                                        "
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="legal_street"
                                      required="required"
                                      class="form-control"
                                      disabled
                                      v-model="userData.legal_street"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_street.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_street
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-3">
                                  <div class="form-group has-validation">
                                    <label>N./Apt.</label>
                                    <input
                                      type="text"
                                      name="legal_number_address"
                                      required="required"
                                      class="form-control"
                                      v-model="userData.legal_number_address"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group has-validation">
                                    <label
                                      >Bairro
                                      <span
                                        class="flag-required"
                                        v-if="
                                          userData.disability_type === 2 ||
                                          userData.disability_type === 4
                                        "
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="legal_neighborhood"
                                      required="required"
                                      class="form-control"
                                      disabled
                                      v-model="userData.legal_neighborhood"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_neighborhood
                                            .error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_neighborhood
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-6">
                                  <div class="form-group has-validation">
                                    <label
                                      >Cidade
                                      <span
                                        class="flag-required"
                                        v-if="
                                          userData.disability_type === 2 ||
                                          userData.disability_type === 4
                                        "
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="legal_city"
                                      required="required"
                                      class="form-control"
                                      disabled
                                      v-model="userData.legal_city"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_city.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_city
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="col-sm-2">
                                  <div class="form-group has-validation">
                                    <label
                                      >UF
                                      <span
                                        class="flag-required"
                                        v-if="
                                          userData.disability_type === 2 ||
                                          userData.disability_type === 4
                                        "
                                        >*</span
                                      ></label
                                    >
                                    <input
                                      type="text"
                                      name="legal_state"
                                      required="required"
                                      class="form-control"
                                      disabled
                                      v-model="userData.legal_state"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.legal_state.error
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.legal_state
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card card-secondary"
                          v-if="
                            userData.disability_type &&
                            userData.disability &&
                            ((userData.disability_type === 2 && yearsPlus18) ||
                              userData.disability_type !== 2)
                          "
                        >
                          <div class="card-body">
                            <div class="row" v-if="userData.driver">
                              <div class="col-sm-4">
                                <div class="form-group">
                                  <div
                                    v-if="userData.cnh4"
                                    class="upload-on-database"
                                  >
                                    <span
                                      class="close-upload"
                                      @click="removeUploadDatabase('cnh4')"
                                    >
                                      <i class="fas fa-times-circle"></i>
                                    </span>
                                    <a :href="userData.cnh4" target="_blank"
                                      >CNH cadastrada</a
                                    >
                                  </div>
                                  <div v-else>
                                    <div v-if="!renderCNH4">
                                      <LoaderComponent />
                                    </div>
                                    <div class="custom-file" v-if="renderCNH4">
                                      <div v-if="CNH4" class="upload-section">
                                        <span
                                          class="close-upload"
                                          @click="removeUpload('CNH4')"
                                        >
                                          <i class="fas fa-times-circle"></i>
                                        </span>
                                        {{ CNH4.name }}
                                      </div>
                                      <div v-else>
                                        <input
                                          type="file"
                                          class="custom-file-input"
                                          id="CNH4"
                                          name="CNH4"
                                          @change="onCNHChange"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="customFile"
                                          >Envie uma CNH</label
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="userData.driver === false">
                              <div class="row">
                                <div class="col-sm-12">
                                  <div
                                    class="alert alert-info alert-dismissible"
                                  >
                                    <h5>
                                      <i
                                        class="icon fas fa-exclamation-triangle"
                                      ></i>
                                      Atenção!
                                    </h5>
                                    Você deverá enviar a CNH de até 3 condutores
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <div
                                      v-if="userData.cnh1"
                                      class="upload-on-database"
                                    >
                                      <span
                                        class="close-upload"
                                        @click="removeUploadDatabase('cnh1')"
                                      >
                                        <i class="fas fa-times-circle"></i>
                                      </span>
                                      <a :href="userData.cnh1" target="_blank"
                                        >CNH cadastrada</a
                                      >
                                    </div>
                                    <div v-else>
                                      <div v-if="!renderCNH1">
                                        <LoaderComponent />
                                      </div>
                                      <div
                                        class="custom-file"
                                        v-if="renderCNH1"
                                      >
                                        <div v-if="CNH1" class="upload-section">
                                          <span
                                            class="close-upload"
                                            @click="removeUpload('CNH1')"
                                          >
                                            <i class="fas fa-times-circle"></i>
                                          </span>
                                          {{ CNH1.name }}
                                        </div>
                                        <div v-else>
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="CNH1"
                                            name="CNH1"
                                            @change="onCNHChange"
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="customFile"
                                            >Envie uma CNH</label
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <div
                                      v-if="userData.cnh2"
                                      class="upload-on-database"
                                    >
                                      <span
                                        class="close-upload"
                                        @click="removeUploadDatabase('cnh2')"
                                      >
                                        <i class="fas fa-times-circle"></i>
                                      </span>
                                      <a :href="userData.cnh2" target="_blank"
                                        >CNH cadastrada</a
                                      >
                                    </div>
                                    <div v-else>
                                      <div v-if="!renderCNH2">
                                        <LoaderComponent />
                                      </div>
                                      <div
                                        class="custom-file"
                                        v-if="renderCNH2"
                                      >
                                        <div v-if="CNH2" class="upload-section">
                                          <span
                                            class="close-upload"
                                            @click="removeUpload('CNH2')"
                                          >
                                            <i class="fas fa-times-circle"></i>
                                          </span>
                                          {{ CNH2.name }}
                                        </div>
                                        <div v-else>
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="CNH2"
                                            name="CNH2"
                                            @change="onCNHChange"
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="customFile"
                                            >Envie uma CNH</label
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <div
                                      v-if="userData.cnh3"
                                      class="upload-on-database"
                                    >
                                      <span
                                        class="close-upload"
                                        @click="removeUploadDatabase('cnh3')"
                                      >
                                        <i class="fas fa-times-circle"></i>
                                      </span>
                                      <a :href="userData.cnh3" target="_blank"
                                        >CNH cadastrada</a
                                      >
                                    </div>
                                    <div v-else>
                                      <div v-if="!renderCNH3">
                                        <LoaderComponent />
                                      </div>
                                      <div
                                        class="custom-file"
                                        v-if="renderCNH3"
                                      >
                                        <div v-if="CNH3" class="upload-section">
                                          <span
                                            class="close-upload"
                                            @click="removeUpload('CNH3')"
                                          >
                                            <i class="fas fa-times-circle"></i>
                                          </span>
                                          {{ CNH3.name }}
                                        </div>
                                        <div v-else>
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="CNH3"
                                            name="CNH3"
                                            @change="onCNHChange"
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="customFile"
                                            >Envie uma CNH</label
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card card-secondary"
                          v-if="(userData.disability_type === 2 && yearsPlus18) || userData.disability_type === 4
                          "
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="alert alert-info alert-dismissible">
                                  Para agilizar o processo, envie um termo de curatela para casos de maiores de 18 anos interditados, deficientes mentais e autistas.
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <div v-if="userData.curatorship_doc" class="upload-on-database">
                                      <span
                                        class="close-upload"
                                        @click="removeUploadDatabase('curatorship_doc')"
                                      >
                                        <i class="fas fa-times-circle"></i>
                                      </span>
                                      <a :href="userData.curatorship_doc" target="_blank"
                                        >Termo de curatela cadastrado</a
                                      >
                                    </div>
                                    <div v-else>
                                      <div v-if="!rendercuratorship_doc">
                                        <LoaderComponent />
                                      </div>
                                      <div class="custom-file" v-if="rendercuratorship_doc">
                                        <div v-if="curatorship_doc" class="upload-section">
                                          <span
                                            class="close-upload"
                                            @click="removeUpload('curatorship_doc')"
                                          >
                                            <i class="fas fa-times-circle"></i>
                                          </span>
                                          {{ curatorship_doc.name }}
                                        </div>
                                        <div v-else>
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="curatorship_doc"
                                            name="curatorship_doc"
                                            @change="onCNHChange"
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="customFile"
                                            >Envie um termo de curatela</label
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="card card-secondary"
                          v-if="
                            userData.disability_type &&
                            userData.disability &&
                            ((userData.disability_type === 2 && yearsPlus18) ||
                              userData.disability_type !== 2)
                          "
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <div
                                    v-if="userData.current_IR"
                                    class="upload-on-database"
                                  >
                                    <span
                                      class="close-upload"
                                      @click="
                                        removeUploadDatabase('current_IR')
                                      "
                                    >
                                      <i class="fas fa-times-circle"></i>
                                    </span>
                                    <a
                                      :href="userData.current_IR"
                                      target="_blank"
                                      >Recibo de entrega IR do ano atual</a
                                    >
                                  </div>
                                  <div v-else>
                                    <div v-if="!rendercurrentIR">
                                      <LoaderComponent />
                                    </div>
                                    <div
                                      class="custom-file"
                                      v-if="rendercurrentIR"
                                    >
                                      <div
                                        v-if="currentIR"
                                        class="upload-section"
                                      >
                                        <span
                                          class="close-upload"
                                          @click="removeUpload('currentIR')"
                                        >
                                          <i class="fas fa-times-circle"></i>
                                        </span>
                                        {{ currentIR.name }}
                                      </div>
                                      <div v-else>
                                        <input
                                          type="file"
                                          class="custom-file-input"
                                          id="currentIR"
                                          name="currentIR"
                                          @change="onIRChange"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="customFile"
                                          >Recibo de entrega IR do ano
                                          atual</label
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <div
                                    v-if="userData.last_IR"
                                    class="upload-on-database"
                                  >
                                    <span
                                      class="close-upload"
                                      @click="removeUploadDatabase('last_IR')"
                                    >
                                      <i class="fas fa-times-circle"></i>
                                    </span>
                                    <a :href="userData.last_IR" target="_blank"
                                      >Recibo de entrega IR ano anterior</a
                                    >
                                  </div>
                                  <div v-else>
                                    <div v-if="!renderlastIR">
                                      <LoaderComponent />
                                    </div>
                                    <div
                                      class="custom-file"
                                      v-if="renderlastIR"
                                    >
                                      <div v-if="lastIR" class="upload-section">
                                        <span
                                          class="close-upload"
                                          @click="removeUpload('lastIR')"
                                        >
                                          <i class="fas fa-times-circle"></i>
                                        </span>
                                        {{ lastIR.name }}
                                      </div>
                                      <div v-else>
                                        <input
                                          type="file"
                                          class="custom-file-input"
                                          id="lastIR"
                                          name="lastIR"
                                          @change="onIRChange"
                                        />
                                        <label
                                          class="custom-file-label"
                                          for="customFile"
                                          >Recibo de entrega IR ano
                                          anterior</label
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" v-if="type === 2">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <div class="custom-file">
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="customFile"
                                    />
                                    <label
                                      class="custom-file-label"
                                      for="customFile"
                                      >Termo de curatela</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-sm-12">
                                <div class="form-group has-validation">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked=""
                                      :value="true"
                                      name="consent_1"
                                      v-model="userData.consent_1"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.consent_1.error
                                      }"
                                    />
                                    <label class="form-check-label"
                                      >Declaro para todos os fins ser possuidor
                                      de renda mensal superior a R$ 3.500,00
                                      (Podendo ser familiar)
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.consent_1
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group has-validation">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked=""
                                      :value="true"
                                      name="consent_2"
                                      v-model="userData.consent_2"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.consent_2.error
                                      }"
                                    />
                                    <label class="form-check-label"
                                      >Declaro para todos os fins não estar com
                                      débitos na Receita Federal
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.consent_2
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group has-validation">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked=""
                                      :value="true"
                                      name="consent_3"
                                      v-model="userData.consent_3"
                                      v-bind:class="{
                                        'is-invalid':
                                          userDataValidation.consent_3.error
                                      }"
                                    />
                                    <label class="form-check-label"
                                      >Declaro para todos os fins ser a
                                      expressão da verdade
                                      <span class="flag-required"
                                        >*</span
                                      ></label
                                    >
                                    <div class="invalid-feedback">
                                      {{
                                        userDataValidation.consent_3
                                          .error_message
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer">
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="message_year_car_bought"
                    >
                      Salvar
                    </button>
                    &nbsp;
                    <router-link :to="back">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Cancelar
                      </button>
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div id="sidebar-overlay" @click="callCloseSidebar"></div>
    </div>
  </body>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import store from '../store/index'
import cep from 'cep-promise'

import { prepareError } from '../utils/errorHandle.js'

import HeaderComponent from '@/components/HeaderComponent.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  name: 'customerData',
  data () {
    return {
      back: '/customers',
      api: process.env.VUE_APP_ROOT_API,
      token: store.getters.token || process.env.VUE_APP_ROOT_TOKEN,
      type: '',
      render: false,
      rendermedical_report: true,
      renderCNH1: true,
      renderCNH2: true,
      renderCNH3: true,
      renderCNH4: true,
      renderlastIR: true,
      rendercurrentIR: true,
      rendercuratorship_doc: true,
      typeOption: '',
      yearsPlus18: null,
      disability: [],
      file: null,
      CNH1: '',
      CNH2: '',
      CNH3: '',
      CNH4: '',
      lastIR: '',
      medical_report: '',
      currentIR: '',
      curatorship_doc: '',
      sendingFile: false,
      users: [],
      previous_years: [],
      message_year_car_bought: false,
      userData: {
        id: null,
        user_id: null,
        disability_type: '',
        disability: '',
        first_name: '',
        last_name: '',
        father_name: '',
        mother_name: '',
        doc: '',
        occupation: '',
        marital_status: '',
        email: '',
        cep: '',
        street: '',
        number_address: '',
        neighborhood: '',
        city: '',
        state: '',
        phone_1: '',
        phone_2: '',
        doc_id: '',
        doc_id_company: '',
        doc_id_uf: '',
        cpf_cnpj: '',
        birth: '',
        birth_country: '',
        legal_first_name: '',
        legal_last_name: '',
        legal_doc: '',
        legal_occupation: '',
        legal_marital_status: '',
        legal_email: '',
        legal_cep: '',
        legal_street: '',
        legal_number_address: '',
        legal_neighborhood: '',
        legal_city: '',
        legal_state: '',
        legal_phone_1: '',
        legal_phone_2: '',
        legal_doc_id: '',
        legal_doc_id_company: '',
        legal_doc_id_uf: '',
        legal_cpf_cnpj: '',
        legal_birth: '',
        legal_birth_country: '',
        consent_1: false,
        consent_2: false,
        consent_3: false,
        driver: null,
        cnh1: '',
        cnh2: '',
        cnh3: '',
        cnh4: '',
        last_IR: '',
        current_IR: '',
        already_bought_car: null,
        last_car_date_bought: '',
        last_car_year_bought: '',
        detran_medical_report: '',
        curatorship_doc: '',
        medical_report: ''
      },
      userDataValidation: {
        already_bought_car: {
          error: false,
          error_message: ''
        },
        last_car_date_bought: {
          error: false,
          error_message: ''
        },
        last_car_year_bought: {
          error: false,
          error_message: ''
        },
        yearsPlus18: {
          error: false,
          error_message: ''
        },
        disability_type: {
          error: false,
          error_message: ''
        },
        disability: {
          error: false,
          error_message: ''
        },
        first_name: {
          error: false,
          error_message: ''
        },
        last_name: {
          error: false,
          error_message: ''
        },
        father_name: {
          error: false,
          error_message: ''
        },
        mother_name: {
          error: false,
          error_message: ''
        },
        doc: {
          error: false,
          error_message: ''
        },
        occupation: {
          error: false,
          error_message: ''
        },
        marital_status: {
          error: false,
          error_message: ''
        },
        email: {
          error: false,
          error_message: ''
        },
        cep: {
          error: false,
          error_message: ''
        },
        street: {
          error: false,
          error_message: ''
        },
        number_address: {
          error: false,
          error_message: ''
        },
        neighborhood: {
          error: false,
          error_message: ''
        },
        city: {
          error: false,
          error_message: ''
        },
        state: {
          error: false,
          error_message: ''
        },
        cpf_cnpj: {
          error: false,
          error_message: ''
        },
        birth: {
          error: false,
          error_message: ''
        },
        birth_country: {
          error: false,
          error_message: ''
        },
        legal_first_name: {
          error: false,
          error_message: ''
        },
        legal_last_name: {
          error: false,
          error_message: ''
        },
        legal_doc: {
          error: false,
          error_message: ''
        },
        legal_occupation: {
          error: false,
          error_message: ''
        },
        legal_marital_status: {
          error: false,
          error_message: ''
        },
        legal_email: {
          error: false,
          error_message: ''
        },
        legal_cep: {
          error: false,
          error_message: ''
        },
        legal_street: {
          error: false,
          error_message: ''
        },
        legal_number_address: {
          error: false,
          error_message: ''
        },
        legal_neighborhood: {
          error: false,
          error_message: ''
        },
        legal_city: {
          error: false,
          error_message: ''
        },
        legal_state: {
          error: false,
          error_message: ''
        },
        legal_cpf_cnpj: {
          error: false,
          error_message: ''
        },
        legal_birth: {
          error: false,
          error_message: ''
        },
        legal_birth_country: {
          error: false,
          error_message: ''
        },
        consent_1: {
          error: false,
          error_message: ''
        },
        consent_2: {
          error: false,
          error_message: ''
        },
        consent_3: {
          error: false,
          error_message: ''
        },
        driver: {
          error: false,
          error_message: ''
        },
        user_id: {
          error: false,
          error_message: ''
        },
        detran_medical_report: {
          error: false,
          error_message: ''
        },
        medical_report: {
          error: false,
          error_message: ''
        }
      },
      stepsCodeMap: {
        100: {
          status: true,
          date: '',
          link: ''
        },
        101: {
          status: false,
          date: '',
          link: ''
        },
        102: {
          status: false,
          date: '',
          link: ''
        },
        103: {
          status: false,
          date: '',
          link: ''
        },
        104: {
          status: false,
          date: '',
          link: ''
        },
        105: {
          status: false,
          date: '',
          link: ''
        },
        106: {
          status: false,
          date: '',
          link: ''
        },
        107: {
          status: false,
          date: '',
          link: ''
        }
      }
    }
  },
  components: {
    HeaderComponent,
    SidebarComponent,
    LoaderComponent
  },
  created () {
    this.getUsers()
    const currentYear = new Date().getFullYear()

    for (let i = 0; i < 10; i++) {
      this.previous_years.push(currentYear - i)
    }

    if (this.$route.query.id) {
      this.getCustomerData(this.$route.query.id)
      return
    }
    this.render = true
  },
  methods: {
    onChangeType (type) {
      this.disability = ''
      this.loadDisability(type)
    },

    onYearChange (e) {
      const currentYear = new Date().getFullYear()

      if (currentYear - 3 < this.userData.last_car_year_bought) {
        this.message_year_car_bought = true
        return
      }

      this.message_year_car_bought = false
    },

    onCNHChange (e) {
      this[`render${e.target.name}`] = false
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        this[e.target.name] = false
        return
      }

      this[e.target.name] = e.target.files[0]

      setTimeout(() => {
        this[`render${e.target.name}`] = true
      }, 2000)
    },

    onMedicalReportChange (e) {
      this[`render${e.target.name}`] = false
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        this[e.target.name] = false
        return
      }

      this[e.target.name] = e.target.files[0]

      setTimeout(() => {
        this[`render${e.target.name}`] = true
      }, 2000)
    },

    onIRChange (e) {
      this[`render${e.target.name}`] = false
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        this[e.target.name] = false
        return
      }

      this[e.target.name] = e.target.files[0]

      setTimeout(() => {
        this[`render${e.target.name}`] = true
      }, 2000)
    },

    removeUpload (upload) {
      this[upload] = null
    },

    removeUploadDatabase (upload) {
      this.userData[upload] = ''
    },

    setSameAddress (e) {
      if (e.target.checked) {
        this.userData.legal_cep = this.userData.cep
        this.userData.legal_street = this.userData.street
        this.userData.legal_number_address = this.userData.number_address
        this.userData.legal_neighborhood = this.userData.neighborhood
        this.userData.legal_city = this.userData.city
        this.userData.legal_state = this.userData.state
      }
    },

    setSamePcDData (e) {
      if (e.target.checked) {
        this.userData.legal_first_name = this.userData.first_name
        this.userData.legal_last_name = this.userData.last_name
        this.userData.legal_doc = this.userData.doc
        this.userData.legal_occupation = this.userData.occupation
        this.userData.legal_marital_status = this.userData.marital_status
        this.userData.legal_email = this.userData.email

        this.userData.legal_phone_1 = this.userData.phone_1
        this.userData.legal_phone_2 = this.userData.phone_2
        this.userData.legal_doc_id = this.userData.doc_id
        this.userData.legal_doc_id_company = this.userData.doc_id_company
        this.userData.legal_doc_id_uf = this.userData.doc_id_uf
        this.userData.legal_cpf_cnpj = this.userData.cpf_cnpj
        this.userData.legal_birth = this.userData.birth
        this.userData.legal_birth_country = this.userData.birth_country

        this.userData.legal_cep = this.userData.cep
        this.userData.legal_street = this.userData.street
        this.userData.legal_number_address = this.userData.number_address
        this.userData.legal_neighborhood = this.userData.neighborhood
        this.userData.legal_city = this.userData.city
        this.userData.legal_state = this.userData.state
      }
    },

    getCustomerData (id) {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN

      this.render = false
      axios
        .get(`${this.api}/customer/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          this.render = true
          const data = response.data.data

          if (data) this.userData = data
          if (data.disability_type === 2) this.yearsPlus18 = true
          this.loadDisability(data.disability_type)
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    },

    loadDisability (type) {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN
      axios
        .get(`${this.api}/def/type/${type}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          this.disability = response.data.data
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    },

    validate () {
      let valid = true

      if (!this.userData.user_id) {
        valid = false
        this.userDataValidation.user_id.error = true
        this.userDataValidation.user_id.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.user_id.error = false
      }

      if (this.userData.already_bought_car === null) {
        valid = false
        this.userDataValidation.already_bought_car.error = true
        this.userDataValidation.already_bought_car.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.already_bought_car.error = false
      }

      if (
        this.userData.already_bought_car &&
        !this.userData.last_car_year_bought
      ) {
        valid = false
        this.userDataValidation.last_car_year_bought.error = true
        this.userDataValidation.last_car_year_bought.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.last_car_year_bought.error = false
      }

      if (
        this.userData.already_bought_car &&
        this.userData.last_car_year_bought
      ) {
        const currentYear = new Date().getFullYear()

        if (
          this.userData.last_car_year_bought < currentYear - 3 &&
          !this.userData.last_car_date_bought
        ) {
          valid = false
          this.userDataValidation.last_car_year_bought.error = true
          this.userDataValidation.last_car_year_bought.error_message =
            'Este campo é obrigatório'
        }
      } else {
        this.userDataValidation.last_car_date_bought.error = false
      }

      if (!this.userData.disability_type) {
        valid = false
        this.userDataValidation.disability_type.error = true
        this.userDataValidation.disability_type.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.disability_type.error = false
      }

      if (this.userData.disability_type === 2 && !this.yearsPlus18) {
        valid = false
        this.userDataValidation.yearsPlus18.error = true
        this.userDataValidation.yearsPlus18.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.yearsPlus18.error = false
      }

      if (!this.userData.disability) {
        valid = false
        this.userDataValidation.disability.error = true
        this.userDataValidation.disability.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.disability.error = false
      }

      if (!this.userData.first_name) {
        valid = false
        this.userDataValidation.first_name.error = true
        this.userDataValidation.first_name.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.first_name.error = false
      }

      if (!this.userData.last_name) {
        valid = false
        this.userDataValidation.last_name.error = true
        this.userDataValidation.last_name.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.last_name.error = false
      }

      if (!this.userData.father_name) {
        valid = false
        this.userDataValidation.father_name.error = true
        this.userDataValidation.father_name.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.father_name.error = false
      }

      if (!this.userData.mother_name) {
        valid = false
        this.userDataValidation.mother_name.error = true
        this.userDataValidation.mother_name.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.mother_name.error = false
      }

      if (!this.userData.marital_status) {
        valid = false
        this.userDataValidation.marital_status.error = true
        this.userDataValidation.marital_status.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.marital_status.error = false
      }

      if (!this.userData.email) {
        valid = false
        this.userDataValidation.email.error = true
        this.userDataValidation.email.error_message = 'Este campo é obrigatório'
      } else {
        this.userDataValidation.email.error = false
      }

      if (!this.userData.cep) {
        valid = false
        this.userDataValidation.cep.error = true
        this.userDataValidation.cep.error_message =
          'Os dados informados estão inválidos'
      } else {
        this.userDataValidation.cep.error = false
      }

      if (!this.userData.street) {
        valid = false
        this.userDataValidation.street.error = true
        this.userDataValidation.street.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.street.error = false
      }

      if (!this.userData.neighborhood) {
        valid = false
        this.userDataValidation.neighborhood.error = true
        this.userDataValidation.neighborhood.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.neighborhood.error = false
      }

      if (!this.userData.city) {
        valid = false
        this.userDataValidation.city.error = true
        this.userDataValidation.city.error_message = 'Este campo é obrigatório'
      } else {
        this.userDataValidation.city.error = false
      }

      if (!this.userData.state) {
        valid = false
        this.userDataValidation.state.error = true
        this.userDataValidation.state.error_message = 'Este campo é obrigatório'
      } else {
        this.userDataValidation.state.error = false
      }

      if (!this.userData.cpf_cnpj) {
        valid = false
        this.userDataValidation.cpf_cnpj.error = true
        this.userDataValidation.cpf_cnpj.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.cpf_cnpj.error = false
      }

      if (!this.userData.birth) {
        valid = false
        this.userDataValidation.birth.error = true
        this.userDataValidation.birth.error_message = 'Este campo é obrigatório'
      } else {
        this.userDataValidation.birth.error = false
      }

      if (!this.userData.birth_country) {
        valid = false
        this.userDataValidation.birth_country.error = true
        this.userDataValidation.birth_country.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.birth_country.error = false
      }

      if (!this.userData.consent_1) {
        valid = false
        this.userDataValidation.consent_1.error = true
        this.userDataValidation.consent_1.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.consent_1.error = false
      }

      if (!this.userData.consent_2) {
        valid = false
        this.userDataValidation.consent_2.error = true
        this.userDataValidation.consent_2.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.consent_2.error = false
      }

      if (!this.userData.consent_3) {
        valid = false
        this.userDataValidation.consent_3.error = true
        this.userDataValidation.consent_3.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.consent_3.error = false
      }

      if (this.userData.driver === null) {
        valid = false
        this.userDataValidation.driver.error = true
        this.userDataValidation.driver.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.driver.error = false
      }

      if (
        this.userData.disability_type === 2 ||
        this.userData.disability_type === 4
      ) {
        if (!this.userData.legal_first_name) {
          valid = false
          this.userDataValidation.legal_first_name.error = true
          this.userDataValidation.legal_first_name.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_first_name.error = false
        }

        if (!this.userData.legal_last_name) {
          valid = false
          this.userDataValidation.legal_last_name.error = true
          this.userDataValidation.legal_last_name.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_last_name.error = false
        }

        if (!this.userData.legal_email) {
          valid = false
          this.userDataValidation.legal_email.error = true
          this.userDataValidation.legal_email.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_email.error = false
        }

        if (!this.userData.legal_cep) {
          valid = false
          this.userDataValidation.legal_cep.error = true
          this.userDataValidation.legal_cep.error_message =
            'Os dados informados estão inválidos'
        } else {
          this.userDataValidation.legal_cep.error = false
        }

        if (!this.userData.legal_street) {
          valid = false
          this.userDataValidation.legal_street.error = true
          this.userDataValidation.legal_street.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_street.error = false
        }

        if (!this.userData.legal_neighborhood) {
          valid = false
          this.userDataValidation.legal_neighborhood.error = true
          this.userDataValidation.legal_neighborhood.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_neighborhood.error = false
        }

        if (!this.userData.legal_city) {
          valid = false
          this.userDataValidation.legal_city.error = true
          this.userDataValidation.legal_city.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_city.error = false
        }

        if (!this.userData.legal_state) {
          valid = false
          this.userDataValidation.legal_state.error = true
          this.userDataValidation.legal_state.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_state.error = false
        }

        if (!this.userData.legal_cpf_cnpj) {
          valid = false
          this.userDataValidation.legal_cpf_cnpj.error = true
          this.userDataValidation.legal_cpf_cnpj.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_cpf_cnpj.error = false
        }

        if (!this.userData.legal_birth) {
          valid = false
          this.userDataValidation.legal_birth.error = true
          this.userDataValidation.legal_birth.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_birth.error = false
        }

        if (!this.userData.legal_birth_country) {
          valid = false
          this.userDataValidation.legal_birth_country.error = true
          this.userDataValidation.legal_birth_country.error_message =
            'Este campo é obrigatório'
        } else {
          this.userDataValidation.legal_birth_country.error = false
        }
      }

      if (this.userData.detran_medical_report === '' || this.userData.detran_medical_report === null) {
        valid = false
        this.userDataValidation.detran_medical_report.error = true
        this.userDataValidation.detran_medical_report.error_message =
          'Este campo é obrigatório'
      } else {
        this.userDataValidation.detran_medical_report.error = false
      }

      return valid
    },

    SendData () {
      if (this.data.password !== this.data.repeat_password) {
        Vue.notify({
          title: 'Senha inválida',
          text: 'Senhas não conferem',
          type: 'error'
        })
        return
      }

      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN

      this.render = false
      axios({
        method: this.$route.query.id ? 'put' : 'post',
        url: !this.$route.query.id
          ? this.api + `/user/create`
          : this.api + `/user/update/` + this.$route.query.id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        data: this.data
      })
        .then((_) => {
          this.$router.go(-1)

          Vue.notify({
            title: 'Dados processados',
            text: 'Todos os dados foram processados com sucesso!',
            type: 'success'
          })
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })

      this.resetData()
    },

    onSubmit () {
      const valid = this.validate()
      if (!valid) return

      this.render = false
      const url = !this.userData.id
        ? `${this.api}/customer/create`
        : `${this.api}/customer/update/${this.userData.id}`

      const formData = new FormData()
      if (this.CNH1) {
        formData.append('CNH1', this.CNH1, this.CNH1.name)
      }
      if (this.CNH2) {
        formData.append('CNH2', this.CNH2, this.CNH2.name)
      }
      if (this.CNH3) {
        formData.append('CNH3', this.CNH3, this.CNH3.name)
      }
      if (this.CNH4) {
        formData.append('CNH4', this.CNH4, this.CNH4.name)
      }
      if (this.lastIR) {
        formData.append('lastIR', this.lastIR, this.lastIR.name)
      }
      if (this.currentIR) {
        formData.append('currentIR', this.currentIR, this.currentIR.name)
      }
      if (this.curatorship_doc) {
        formData.append('curatorship_doc', this.curatorship_doc, this.curatorship_doc.name)
      }
      if (this.medical_report) {
        formData.append('medical_report', this.medical_report, this.medical_report.name)
      }
      for (const key in this.userData) {
        formData.append(key, this.userData[key])
      }

      axios({
        method: this.userData.id ? 'put' : 'post',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token
        },
        data: formData
      })
        .then((_) => {
          Vue.notify({
            title: 'Dados processados',
            text: 'Todos os dados foram processados com sucesso!',
            type: 'success'
          })
          this.render = true
        })
        .catch((error) => {
          prepareError(error.response.status, this.$router, Vue.notify)
          this.render = true
        })
    },

    getUsers () {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN
      this.users = []

      axios
        .get(this.api + `/user`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          this.users = response.data.data
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    },

    resetCep (legal = false) {
      if (!legal) {
        this.userData.street = ''
        this.userData.legal_number_address = ''
        this.userData.neighborhood = ''
        this.userData.city = ''
        this.userData.state = ''
      } else {
        this.userData.legal_street = ''
        this.userData.legal_legal_number_address = ''
        this.userData.legal_neighborhood = ''
        this.userData.legal_city = ''
        this.userData.legal_state = ''
      }
    },

    getCep (legal = false) {
      const self = this

      if (!legal) {
        if (this.userData.cep.length === 8) {
          cep(this.userData.cep).then((cep) => {
            self.userData.street = cep.street
            self.userData.neighborhood = cep.neighborhood
            self.userData.city = cep.city
            self.userData.state = cep.state
          })
        } else {
          this.resetCep()
        }
      } else {
        if (this.userData.legal_cep.length === 8) {
          cep(this.userData.legal_cep).then((cep) => {
            self.userData.legal_street = cep.street
            self.userData.legal_neighborhood = cep.neighborhood
            self.userData.legal_city = cep.city
            self.userData.legal_state = cep.state
          })
        } else {
          this.resetCep(true)
        }
      }
    },

    callCloseSidebar () {
      this.$func()
    }
  }
}
</script>

<style scoped>
.add-button {
  text-decoration: none;
}

.ban-icon:hover {
  color: #dc3545;
}

.check-icon:hover {
  color: #28a745;
}

.overflow-x {
  overflow-x: auto;
}

.upload-section {
  background-color: #c7eaff;
  padding: 10px;
  border-radius: 5px;
}

.close-upload {
  position: absolute;
  right: -5px;
  top: -5px;
  color: red;
  cursor: pointer;
}

.upload-on-database {
  display: inline;
  padding: 8px 30px;
  background-color: beige;
  position: relative;
}
</style>
