const DETAILS = 'Ver detalhes'
const ALTER = 'Alterar cadastro'
const ACTIVATE = 'Ativar'
const INACTIVATE = 'Inativar'

module.exports = {
  DETAILS,
  ALTER,
  ACTIVATE,
  INACTIVATE,
  typeDoc: {
    1: 'Carta de isenção',
    2: 'Laudo médico',
    3: 'Laudo assinado'
  },
  stepMap: {
    101: 'Aguardando cadastro de PcD',
    102: 'Aguardando pagamento de inscrição PcD',
    103: 'Gerando laudo',
    104: 'Laudo está pronto para consulta médica',
    105: 'Aguardando o envio de laudo médico',
    106: 'Aguardando pagamento do processo PcD',
    107: 'Processo concluído'
  }
}
