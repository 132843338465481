<template>
  <body
    id="mySidebar"
    class="
      sidebar-mini
      layout-fixed layout-navbar-fixed layout-footer-fixed
      sidebar-collapse
      text-sm
    "
    style="height: auto"
  >
    <div class="wrapper">
      <HeaderComponent />
      <SidebarComponent />

      <div id="myWrapper" class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-6 col-sm-6">
                <h1>Adicionar documento</h1>
              </div>
              <div class="col-6 col-sm-6">
                <span class="float-sm-right add-button" v-if="render">
                  <router-link :to="back">
                    <button type="button" class="btn btn-block btn-outline-secondary">
                      Voltar
                    </button>
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <LoaderComponent v-if="!render" />

                  <div v-else>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label>Nome do documento <span class="flag-required">*</span></label>
                          <input
                            type="text"
                            class="form-control"
                            name="name"
                            v-model="data.name"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label>Tipo de documento <span class="flag-required">*</span></label>
                          <select
                            class="form-control"
                            name="type"
                            v-model="data.type"
                          >
                            <option disabled value="" selected>
                              Selecione uma opção
                            </option>
                            <option :value="1">
                              Carta de isenção
                            </option>
                            <option :value="2">
                              Laudo médico
                            </option>
                            <option :value="3">
                              Laudo assinado
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="data.type">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label
                            >{{ data.type === 1 ? 'Carta de isenção' : 'Laudo médico' }}
                            <span class="flag-required">*</span></label
                          > (.pdf/.png/.jpg)

                          <LoaderComponent v-if="!renderDoc" />

                          <input
                            v-if="renderDoc"
                            @change="onFileChange"
                            type="file"
                            name="file"
                            required="required"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label>Cliente <span class="flag-required">*</span></label>
                          <select
                            class="form-control"
                            name="customer_id"
                            v-model="data.customer_id"
                          >
                            <option disabled value="" selected>
                              Selecione uma opção
                            </option>
                            <option
                              v-for="user in users"
                              :value="user.id"
                              v-bind:key="user.id"
                            >
                              {{ user.first_name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label>Descrição</label>
                          <textarea
                            class="form-control"
                            rows="3"
                            name="description"
                            v-model="data.description"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="card-footer">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    :disabled="!data.name || !data.type || !file"
                    @click="sendData()"
                  >
                    Salvar
                  </button>
                  &nbsp;
                  <router-link :to="back">
                    <button type="button" class="btn btn-outline-secondary">
                      Cancelar
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div id="sidebar-overlay" @click="callCloseSidebar"></div>
    </div>
  </body>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import store from '../store/index'

import { prepareError } from '../utils/errorHandle.js'

import HeaderComponent from '@/components/HeaderComponent.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  name: 'AddDocs',
  components: {
    HeaderComponent,
    SidebarComponent,
    LoaderComponent
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: true,
      back: '/docs',
      users: [],
      file: null,
      renderDoc: true,
      data: {
        name: '',
        customer_id: '',
        type: '',
        description: ''
      }
    }
  },
  created () {
    this.getUsers()
  },
  methods: {
    resetData () {
      this.data = {
        user: '',
        last_name: '',
        email: '',
        password: '',
        repeat_password: '',
        type: '',
        status: ''
      }
    },

    onFileChange (e) {
      this.renderDoc = false

      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        this.file = false
        return
      }
      this.file = e.target.files[0]

      setTimeout(() => {
        this.renderDoc = true
      }, 4000)
    },

    sendData () {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN

      this.render = false

      const formData = new FormData()
      if (this.file) {
        formData.append('file', this.file, this.file.name)
      }
      for (const key in this.data) {
        formData.append(key, this.data[key])
      }

      axios({
        method: 'post',
        url: this.api + `/doc`,
        headers: {
          Authorization: 'Bearer ' + token
        },
        data: formData
      })
        .then((_) => {
          this.$router.go(-1)

          Vue.notify({
            title: 'Dados processados',
            text: 'Todos os dados foram processados com sucesso!',
            type: 'success'
          })
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })

      this.resetData()
    },
    getUsers () {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN
      this.users = []

      axios
        .get(this.api + `/user`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          this.users = response.data.data
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    },
    callCloseSidebar () {
      this.$func()
    }
  }
}
</script>

<style scoped>
.add-button {
  text-decoration: none;
}

.ban-icon:hover {
  color: #dc3545;
}

.check-icon:hover {
  color: #28a745;
}

.overflow-x {
  overflow-x: auto;
}
</style>
