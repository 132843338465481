<template>
  <body
    id="mySidebar"
    class="
      sidebar-mini
      layout-fixed layout-navbar-fixed layout-footer-fixed
      sidebar-collapse
      text-sm
    "
    style="height: auto"
  >
    <div class="wrapper">
      <HeaderComponent />
      <SidebarComponent />

      <div id="myWrapper" class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-6 col-sm-6">
                <h1>Dados pessoais</h1>
              </div>
              <div class="col-6 col-sm-6">
                <span class="float-sm-right add-button" v-if="render">
                  <router-link to="/customer_data">
                    <button type="button" class="btn btn-block btn-success">
                      Cadastrar
                    </button>
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </section>

        <div
          class="modal fade"
          id="myModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel">
                  Alterando o status do usuário
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="alert alert-warning alert-dismissible">
                  <h5>
                    <i class="icon fas fa-exclamation-triangle"></i> Atenção!
                  </h5>
                  Você está

                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  data-dismiss="modal"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>

        <section class="content">
          <!-- <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Filtros</h3>
                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-5">
                      <div class="form-group">
                        <label>Cliente</label>
                        <input
                          type="text"
                          class="form-control"
                          name="name"
                          v-model="filters.name"
                          @keydown.enter="filterData()"
                        />
                      </div>
                    </div>

                    <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                      <div class="form-group">
                        <label>UF</label>
                        <select
                          class="form-control"
                          name="uf"
                          v-model="filters.uf"
                          @keydown.enter="filterData()"
                        >
                          <option disabled value="">Selecione o estado</option>
                          <option value="1">ES</option>
                          <option value="0">RJ</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-6 col-lg-5">
                      <div class="form-group">
                        <label>Cidade</label>
                        <select
                          class="form-control"
                          name="city"
                          v-model="filters.city"
                          @keydown.enter="filterData()"
                        >
                          <option disabled value="">
                            Selecione uma cidade
                          </option>
                          <option value="1">Cachoeiro de Itapemirim</option>
                          <option value="0">Campos</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                      <div class="form-group">
                        <label>CPF</label>
                        <the-mask
                          :mask="['###.###.###-##', '##.###.###/####-##']"
                          name="cnpj"
                          v-model="filters.cnpj"
                          class="form-control"
                          @keydown.native.enter="filterData()"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                      <div class="form-group">
                        <label>Status</label>
                        <select
                          class="form-control"
                          name="status"
                          v-model="filters.status"
                          @keydown.enter="filterData()"
                        >
                          <option disabled value="">
                            Selecione o status..
                          </option>
                          <option value="1">Ativo</option>
                          <option value="0">Inativo</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="filterData()"
                  >
                    Filtrar
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="resetFilters()"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div> -->

          <div
            class="alert alert-warning alert-dismissible"
            v-if="hasFilter && render"
          >
            <h5><i class="icon fas fa-exclamation-triangle"></i> Atenção!</h5>
            Resultados exibidos com base nos filtros selecionados
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Lista de dados pessoais cadastrados</h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <LoaderComponent v-if="!render" />
                  <div
                    v-else
                    id="example2_wrapper"
                    class="dataTables_wrapper dt-bootstrap4"
                  >
                    <div class="row">
                      <div class="col-sm-12 col-md-6"></div>
                      <div class="col-sm-12 col-md-6"></div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-12 overflow-x">
                          <table
                            class="
                              table table-bordered table-striped
                              dataTable
                              dtr-inline
                            "
                            role="grid"
                          >
                            <thead>
                              <tr role="row">
                                <th tabindex="0" rowspan="1" colspan="1">
                                  Nome
                                </th>
                                <th tabindex="0" rowspan="1" colspan="1">
                                  Sobrenome
                                </th>
                                <th tabindex="0" rowspan="1" colspan="1">
                                  E-mail
                                </th>
                                <th tabindex="0" rowspan="1" colspan="1">
                                  CEP
                                </th>
                                <th tabindex="0" rowspan="1" colspan="1">
                                  Endereço
                                </th>
                                <th tabindex="0" rowspan="1" colspan="1">
                                  Cidade
                                </th>
                                <th tabindex="0" rowspan="1" colspan="1">
                                  UF
                                </th>
                                <!-- <th tabindex="0" rowspan="1" colspan="1">
                                  Situação
                                </th> -->
                                <th tabindex="0" rowspan="1" colspan="1">
                                  Status
                                </th>
                                <th
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  class="operation-title"
                                >Operações</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                role="row"
                                v-for="data in customers"
                                :key="data.id"
                              >
                                <td nowrap>{{ data.first_name.toUpperCase() }}</td>
                                <td nowrap>{{ data.last_name }}</td>
                                <td nowrap>{{ data.email }}</td>
                                <td nowrap>{{ data.cep }}</td>
                                <td nowrap>{{ data.street }}</td>
                                <td nowrap>{{ data.city }}</td>
                                <td nowrap>{{ data.state }}</td>
                                <!-- <td nowrap>
                                  <span
                                    :title="data.situation"
                                    class="badge bg-warning status-box"
                                    v-if="data.situation == 1"
                                    >Aguardando emissão de laudo</span
                                  >
                                  <span
                                    :title="data.situation"
                                    class="badge bg-success status-box"
                                    v-if="data.situation == 2"
                                    >Concluído</span
                                  >
                                </td> -->
                                <td nowrap>
                                  <span
                                    :title="data.status"
                                    class="badge bg-success status-box"
                                    v-if="data.status == 1"
                                    >Ativo</span
                                  >
                                  <span
                                    :title="data.status"
                                    class="badge bg-danger status-box"
                                    v-else
                                    >Inativo</span
                                  >
                                </td>
                                <td class="operation-data">
                                  <router-link
                                    aria-label="Alterar"
                                    data-balloon-pos="up"
                                    data-balloon-length="small"
                                    :to="{ path: '/customer_data', query: { id: data.id } }"
                                  >
                                    <i class="far fa-edit"></i>
                                  </router-link>
                                  <span
                                    aria-label="Remover"
                                    data-balloon-pos="up"
                                    data-balloon-length="small"
                                    data-toggle="modal"
                                    data-target="#delete-modal"
                                    @click="idToDelete = data.id"
                                  >
                                    <i class="fas fa-trash-alt danger-icon"></i>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="pagination-desc-footer" v-if="lastPage">
                          Total:
                          {{ Number(total).toLocaleString('pt-BR') }} registros
                        </div>
                        <div class="">
                          <paginate
                            v-if="lastPage"
                            v-model="page"
                            :page-count="lastPage"
                            :click-handler="getDataPage"
                            :prev-text="'Anterior'"
                            :next-text="'Próximo'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :active-class="'page-active'"
                            :margin-pages="2"
                            :page-range="3"
                          >
                          </paginate>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
            <!-- /.col -->
          </div>
        </section>
      </div>
      <div id="sidebar-overlay" @click="callCloseSidebar"></div>
    </div>
  </body>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import store from '../store/index'

import { prepareError } from '../utils/errorHandle.js'
import constants from '../utils/constants.js'
import HeaderComponent from '@/components/HeaderComponent.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  name: 'customers',
  components: {
    HeaderComponent,
    SidebarComponent,
    LoaderComponent
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: false,
      lastPage: 0,
      page: 1,
      total: 0,
      constants: constants,
      customers: '',
      filters: {
        name: '',
        cnpj: '',
        status: ''
      },
      filtersDefined: {
        name: '',
        cnpj: '',
        status: ''
      },
      hasFilter: false
    }
  },
  created () {
    this.render = false
    this.getDataPage(1)
  },
  methods: {
    resetFilters () {
      this.hasFilter = false
      this.filters = {
        name: '',
        cnpj: '',
        status: ''
      }
      this.filtersDefined = {
        name: '',
        cnpj: '',
        status: ''
      }
      this.getDataPage(1)
    },
    filterData () {
      this.hasFilter = true
      this.filtersDefined = this.filters
      this.getDataPage(1)
    },
    getDataPage (pageNum) {
      this.filters = { ...this.filtersDefined }
      this.filtersDefined.name = this.filtersDefined.name.toLowerCase()

      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN
      this.customers = []
      this.render = false
      axios
        .get(this.api + `/customer?page=${pageNum}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          this.render = true
          this.customers = response.data.data
          this.lastPage = response.data.lastPage
          this.page = pageNum
          this.total = response.data.total
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    },
    callCloseSidebar () {
      this.$func()
    }
  }
}
</script>

<style scoped>
.add-button {
  text-decoration: none;
}

.ban-icon:hover {
  color: #dc3545;
}

.check-icon:hover {
  color: #28a745;
}

.overflow-x {
  overflow-x: auto;
}

.danger-icon {
  color: red;
}
</style>
