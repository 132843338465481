<template>
  <aside class="main-sidebar elevation-4"
    :class="{
      'sidebar-dark-success': userType === 1,
      'sidebar-light-success': userType === 0}
    ">
    <!-- Brand Logo -->
    <img class="logo-sidebar" src="@/assets/img/logo-main.webp" />
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <router-link to="/" class="nav-link" exact-active-class="active">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="userType === 1">
            <router-link
              to="/customers"
              class="nav-link"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-table"></i>
              <p>Dados pessoais</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="userType === 1">
            <router-link
              to="/transactions"
              class="nav-link"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-scroll"></i>
              <p>Etapas e transações</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="userType === 1">
            <router-link
              to="/docs"
              class="nav-link"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-file"></i>
              <p>Documentos</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="userType === 1">
            <router-link
              to="/defs"
              class="nav-link"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-pills"></i>
              <p>Deficiências</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="userType === 1">
            <router-link
              to="/users"
              class="nav-link"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-user"></i>
              <p>Usuários</p>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link
              to="/settings"
              class="nav-link"
              exact-active-class="active"
            >
              <i class="nav-icon fas fa-cog"></i>
              <p>Configurações</p>
            </router-link>
          </li> -->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import store from '../store/index'

export default {
  name: 'sidebar',
  data () {
    return {
      userType: null
    }
  },
  created () {
    this.userType = store.getters.signInUser.type // fake to user / admin is 1
  }
}
</script>

<style>
.logo-sidebar {
  width: 85%;
  padding: 25px 44px;
}

.nav-link {
  display: flex !important;
}

.sidebar {
  margin-top: 0px !important;
}

.main-sidebar {
  text-align: center !important;
}
</style>
