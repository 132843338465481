import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

Vue.use(Vuex)

const AUTH_REQUEST = 'AUTH_REQUEST'
const AUTH_SUCCESS = 'AUTH_SUCCESS'
const AUTH_ERROR = 'AUTH_ERROR'
const AUTH_LOGOUT = 'AUTH_LOGOUT'

const AUTH_URL = process.env.VUE_APP_ROOT_API + '/auth/signin'

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('user-token') || '', // eslint-disable-line
    signInUser: JSON.parse(localStorage.getItem('signin-user')) || '', // eslint-disable-line
    status: '',
    hasLoadedOnce: false,
    profile: {}
  },
  mutations: {
    [AUTH_REQUEST]: (state) => {
      state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, data) => {
      state.status = 'success'
      state.token = data.token
      state.signInUser = data.signInUser
      state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: (state) => {
      state.status = 'error'
      state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: (state) => {
      state.profile = {}
    }
  },
  actions: {
    [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST)
        axios({ url: AUTH_URL, data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token
            const signInUser = resp.data.signin_user
            localStorage.setItem('user-token', token) // eslint-disable-line
            localStorage.setItem('signin-user', JSON.stringify(signInUser))
            commit(AUTH_SUCCESS, { token, signInUser })
            resolve(resp)
          })
          .catch(err => {
            commit(AUTH_ERROR, err)
            localStorage.removeItem('user-token') // eslint-disable-line
            localStorage.removeItem('signin-user') // eslint-disable-line
            reject(err)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_LOGOUT)
        localStorage.removeItem('user-token') // eslint-disable-line
        localStorage.removeItem('signin-user') // eslint-disable-line
        resolve()
      })
    }
  },
  modules: {
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    token: state => state.token,
    signInUser: state => state.signInUser
  }
})
