<template>
  <body class="hold-transition lockscreen">
    <div class="lockscreen-wrapper">
      <div class="lockscreen-logo logo">
        <a href="#"><img src="../assets/img/logo-main.webp" alt="Logo SOS isenta"></a>
      </div>

      <Loader v-if="!render"/>

      <div v-else>
        <div class="alert alert-danger text-center" role="alert" v-if="notFound">
          Usuário não encontrado
        </div>

        <div class="alert alert-danger text-center" role="alert" v-if="error">
          Falha ao validadar seu e-mail, entre em contato com o desenvolvedor
        </div>

        <div v-if="success">
          <h3 class="lockscreen-name welcome">Bem-vindo!</h3>

          <div class="lockscreen-item">

          </div>

          <div class="help-block text-center message">
            Você acaba de validar o seu e-mail!
          </div>

          <div class="text-center ">
            <a @click="$router.push('/login')" class="text-center link access">Acessar minha conta</a>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import axios from 'axios'

import Loader from '../components/LoaderComponent'

export default {
  name: 'validadeEmail',
  components: {
    Loader
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: true,
      error: false,
      notFound: false,
      success: false
    }
  },
  created: function () {
    this.render = false

    axios({
      method: 'post',
      url: process.env.VUE_APP_ROOT_API + '/user/verify?token=' + this.$route.query.token,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(_ => {
        this.render = true
        this.success = true
      })
      .catch(err => {
        this.render = true

        switch (err.response.status) {
          case 404:
            this.notFound = true
            break
          default:
            this.error = true
            break
        }
      })
  },
  methods: {
    validate () {
    }
  }
}
</script>
<style scoped>
.lockscreen {
  height: 100vh;
  background-color: #DCDCDC;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
}

.lockscreen-wrapper {
  margin-top: 0px;
  width: 420px;
  background-color: #FFF;
  padding: 20px 20px 40px 20px;
  border-radius: 0.25rem;
}

.lockscreen-logo {
  margin-bottom: 0px;
}

.logo {
    width: 100%;
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo img {
    width: 233.37px;
    height: 65px;
}

.welcome {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.5rem;
}

.message {
  font-size: 1.25rem;
  line-height: 1.625rem;
  margin-bottom: 30px;
}

.access {
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: #39A0DD;
  text-decoration: none;
}
</style>
