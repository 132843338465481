<template>
<body class="register-page">
  <div class="register-box">
    <div class="register-logo">DRSMS</div>
    <div class="card">
      <div class="card-body register-card-body">
        <p class="register-box-msg">Recuperar senha</p>

        <div class="alert alert-danger text-center" role="alert" v-if="errorPassword">
          Senhas devem ser iguais
        </div>

        <div class="alert alert-danger text-center" role="alert" v-if="errorNotFound">
          Usuário não encontrado
        </div>

        <div class="alert alert-danger text-center" role="alert" v-if="error">
          Algo deu errado, entre em contato
        </div>

        <div v-if="success">
          <div class="alert alert-success text-center" role="alert">
            Sua senha foi alterada com sucesso
          </div>

          <p class="mb-0 text-center">
            <a @click="$router.push('/login')" class="text-center link">Acessar conta</a>
          </p>
        </div>

        <Loader v-if="!render"/>

        <form v-if="render && !success" autocomplete="off">
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="Nova senha" v-model="dataRegister.password" required/>
            <div class="input-group-append">
              <div class="input-group-text"  @click="showPassword ()">
                <span class="fa" :class="{ 'fa-eye': password == 'password', 'fa-eye-slash' : password == 'text'}" ></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input v-bind:type="password" class="form-control" placeholder="Repetir senha" v-model="dataRegister.repeat_password" required/>
            <div class="input-group-append">
              <div class="input-group-text btn-eye"  @click="showPassword ()">
                <span class="fa" :class="{ 'fa-eye': password == 'password', 'fa-eye-slash' : password == 'text'}" ></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-success btn-block"
                @click="recoveryPassword()"
                :disabled="!dataRegister.password || !dataRegister.repeat_password"
              >Alterar senha</button>
            </div>
          </div>

          <hr>

          <div class="text-center">
            <a @click="$router.push('/login')" class="text-center link">Acessar minha conta</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</body>
</template>

<script>
import axios from 'axios'

import Loader from '../components/LoaderComponent'

export default {
  name: 'recoveryPassword',
  components: {
    Loader
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: true,
      dataRegister: {
        password: '',
        repeat_email: ''
      },
      errorPassword: false,
      error: false,
      errorNotFound: false,
      success: false,
      password: 'password'
    }
  },
  methods: {
    recoveryPassword () {
      this.errorPassword = false
      this.error = false
      this.errorNotFound = false
      this.render = false

      if (this.dataRegister.password !== this.dataRegister.repeat_password) {
        this.errorPassword = true
        this.render = true
        return
      }

      axios({
        method: 'post',
        url: process.env.VUE_APP_ROOT_API + '/user/new-password?token=' + this.$route.query.token,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.dataRegister
      })
        .then(_ => {
          this.render = true
          this.success = true
        })
        .catch(err => {
          this.render = true

          switch (err.response.status) {
            case 404:
              this.errorNotFound = true
              break
            default:
              this.error = true
              break
          }
        })
    },
    showPassword () {
      this.password === 'password' ? this.password = 'text' : this.password = 'password'
    }
  }
}
</script>

<style>
.btn-eye {
  cursor: pointer;
}
</style>
