<template>
<body class="register-page">
  <div class="register-box">
    <img class="login-logo" src="@/assets/img/logo-main.webp">
    <div class="card">
      <div class="card-body register-card-body">
        <p class="register-box-msg">Recuperar senha</p>

        <div class="alert alert-danger text-center" role="alert" v-if="errorEmail">
          E-mails devem ser iguais
        </div>

        <div class="alert alert-danger text-center" role="alert" v-if="errorNotFound">
          Usuário não encontrado
        </div>

        <div class="alert alert-danger text-center" role="alert" v-if="error">
          Algo deu errado, entre em contato
        </div>

        <div class="alert alert-success text-center" role="alert" v-if="success">
          Um e-mail de recuperação foi enviado para o e-mail informado
        </div>

        <Loader v-if="!render"/>

        <form v-if="render && !success" autocomplete="off">
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="E-mail" v-model="dataRegister.email" required/>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Repetir e-mail" v-model="dataRegister.repeat_email" required/>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-success btn-block"
                @click="recoveryPassword()"
                :disabled="!dataRegister.email || !dataRegister.repeat_email"
              >Recuperar senha</button>
            </div>
          </div>
        </form>
        <hr>
        <p class="mb-0">
          <a @click="$router.push('/login')" class="text-center link">Já tenho uma conta</a>
        </p>
      </div>
    </div>
  </div>
</body>
</template>

<script>
import axios from 'axios'

import Loader from '../components/LoaderComponent'
import { loadRecaptcha, catchRecaptchaToken } from '../utils/recaptchaVerification'

export default {
  name: 'recoveryPassword',
  components: {
    Loader
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: true,
      dataRegister: {
        email: '',
        repeat_email: '',
        recaptchaToken: '',
        recaptchaAction: 'recoveryPassword'
      },
      errorEmail: false,
      error: false,
      errorNotFound: false,
      success: false
    }
  },
  mounted () {
    loadRecaptcha()
  },
  methods: {
    recoveryPassword () {
      const self = this

      const proccessRecoveryPassword = () => {
        self.errorEmail = false
        self.error = false
        self.errorNotFound = false
        self.render = false

        if (self.dataRegister.email !== self.dataRegister.repeat_email) {
          self.errorEmail = true
          self.render = true
          return
        }
        axios({
          method: 'post',
          url: process.env.VUE_APP_ROOT_API + '/user/recovery-password?email=' + self.dataRegister.email,
          headers: {
            'Content-Type': 'application/json'
          },
          data: self.dataRegister
        })
          .then(_ => {
            self.render = true
            self.success = true
          })
          .catch(err => {
            self.render = true

            switch (err.response.status) {
              case 404:
                self.errorNotFound = true
                break
              default:
                self.error = true
                break
            }
          })
      }

      catchRecaptchaToken(self.dataRegister.recaptchaAction).then((token) => {
        self.dataRegister.recaptchaToken = token
        if (self.dataRegister.recaptchaToken) {
          proccessRecoveryPassword()
        }
      })
    }
  }
}
</script>

<style scoped>
.register-box {
  text-align: center
}

.register-box .login-logo {
  width: 150px;
  margin-bottom: 38px;
}
</style>
