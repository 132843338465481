module.exports = {
  prepareError (statusCode, routerInstance, notifyInstace) {
    if (statusCode === 401) {
      localStorage.removeItem('user-token') // eslint-disable-line
      localStorage.removeItem('signin-user') // eslint-disable-line
      routerInstance.push('/login')
    } else if (statusCode === 500) {
      notifyInstace({
        title: 'Dados processados',
        text: `Falha ao processar os dados!`,
        type: 'error'
      })
    }
  }
}
