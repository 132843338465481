<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light navbar-children">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" @click="toggleMenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Início</router-link>
      </li>
    </ul>
    <!-- Center logo -->
    <img id="logo-center" class="logo-center-active" src="@/assets/img/logo-main.webp" alt="Logo DRSMS">
    <!-- Right navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="#" @click="logout">
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import store from '../store/index'

export default {
  data: function () {
    return {
      signInUser: store.getters.signInUser,
      controll: false
    }
  },
  methods: {
    logout: function () {
      const self = this

      store.dispatch('AUTH_LOGOUT').then(() => {
        self.$router.push('/login')
      })
    },
    toggleMenu () {
      this.controll = !this.controll
      const sidebar = document.getElementById('mySidebar')
      const logoCenterHM = document.getElementById('logo-center').classList
      const contentWrapper = document.getElementById('myWrapper')
      const screenHeight = screen.height - 52 // screen height - 52 px of the navbar

      sidebar.classList.toggle('sidebar-collapse') // Add/Remove the .sidebar-collapse class

      if (logoCenterHM.contains('logo-center-active') && !sidebar.classList.contains('sidebar-collapse')) {
        logoCenterHM.remove('logo-center-active')
        logoCenterHM.add('logo-center-desactive')
      } else {
        logoCenterHM.add('logo-center-active')
        logoCenterHM.remove('logo-center-desactive')
      }

      // Controls the div height that wrap the page content
      if (window.matchMedia('(min-width: 992px)').matches) {
        this.controll ? contentWrapper.setAttribute('style', `min-height: ${screenHeight}px`)
          : contentWrapper.setAttribute('style', `min-height: ${screenHeight}px`)
        if (sidebar.classList.contains('sidebar-closed')) sidebar.classList.remove('sidebar-closed')
        if (sidebar.classList.contains('sidebar-open')) sidebar.classList.remove('sidebar-open')
      } else {
        this.controll ? contentWrapper.setAttribute('style', `min-height: ${screenHeight}px`)
          : contentWrapper.setAttribute('style', `min-height: ${screenHeight}px`)
        sidebar.classList.remove('sidebar-closed')
        sidebar.classList.add('sidebar-open')
      }
    }
  }
}
</script>

<style scoped>
.navbar-nav {
  display: flex;
  align-items: baseline;
}

.nav-link {
  cursor: pointer;
}

.sidebar-closed {
  display: none;
}

.user-info {
  gap: 5px;
  border-right: 1px solid #aaaaaa80;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.avatar-info {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.dropdown-button {
  display: inline-flex;
  align-items: center;
}

.dropdown-caret {
  border-bottom: 0 solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top-style: solid;
  border-top-width: 4px;
  content: "";
  display: inline-block;
  height: 0;
  vertical-align: middle;
  width: 0;
}

.font-size {
  font-size: 0.875rem;
}

.navbar-children {
  display: flex;
  justify-content: space-between;
}

.logo-center-active {
  display: block;
  height: 1.875rem;
}

.logo-center-desactive {
  display: none;
}
</style>
