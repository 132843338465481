<template>
  <div :class="'info-box ' + bgBox">
    <LoaderComponent v-if="!infoBoxText || infoBoxNumber === null" />

    <span class="info-box-icon" v-if="infoBoxText && infoBoxNumber >= 0">
      <i :class="boxFaIcon"></i>
    </span>

    <div class="info-box-content" v-if="infoBoxText && infoBoxNumber >= 0">
      <span class="info-box-text">{{ infoBoxText }}</span>
      <span class="info-box-number">{{ transformToDecimal(infoBoxNumber) }}</span>
    </div>
  </div>
</template>

<script>
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  name: 'infobox',
  components: {
    LoaderComponent
  },
  props: {
    bgBox: {
      type: String,
      default: ''
    },
    boxFaIcon: {
      type: String,
      default: ''
    },
    infoBoxText: {
      type: String,
      default: ''
    },
    infoBoxNumber: {
      type: Number,
      default: null
    }
  },
  methods: {
    transformToDecimal (infoBoxNumber) {
      return Number(infoBoxNumber).toLocaleString('pt-BR')
    }
  }
}
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.info-box .info-box-number {
  display: block;
  font-weight: 700;
  font-size: 21px;
  padding-top: 10px;
}
</style>
