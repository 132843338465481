<template>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
      <InfoBoxComponent
        bgBox='bg-primary'
        boxFaIcon="fas fa-users"
        infoBoxText='Clientes registrados'
        :infoBoxNumber='infoBoxNumberOfCustomers'
      />
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
      <InfoBoxComponent
        bgBox='bg-warning'
        boxFaIcon="fas fa-hand-holding-usd"
        infoBoxText='Aguardando taxa de inscrição'
        :infoBoxNumber='infoBoxNumberOfCustomersPgtoTax'
      />
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
      <InfoBoxComponent
        bgBox='bg-warning-light'
        boxFaIcon="fas fa-file-invoice-dollar"
        infoBoxText='Aguardando pagamento final'
        :infoBoxNumber='infoBoxNumberOfCustomersPgtoFinal'
      />
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
      <InfoBoxComponent
        bgBox='bg-success'
        boxFaIcon="fas fa-wallet"
        infoBoxText='Vendas concluidas'
        :infoBoxNumber='infoBoxNumberOfCustomersPgtoComplete'
      />
    </div>
  </div>
</template>

<script>
import store from '../store/index'
import axios from 'axios'
import Vue from 'vue'

import { prepareError } from '../utils/errorHandle.js'

import InfoBoxComponent from '@/components/InfoBoxComponent.vue'

export default {
  name: 'adminDashboard',
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      token: store.getters.token || process.env.VUE_APP_ROOT_TOKEN,
      infoBoxNumberOfCustomers: 0,
      infoBoxNumberOfCustomersPgtoTax: 0,
      infoBoxNumberOfCustomersPgtoFinal: 0,
      infoBoxNumberOfCustomersPgtoComplete: 0
    }
  },
  components: {
    InfoBoxComponent
  },
  created () {
    this.getTopMetrics()
  },
  methods: {
    getTopMetrics () {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN

      this.render = false
      axios
        .get(`${this.api}/admin-dashboard/top-metrics`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          const data = response.data.data

          this.infoBoxNumberOfCustomers = data.register
          this.infoBoxNumberOfCustomersPgtoTax = data.waitInitialPay
          this.infoBoxNumberOfCustomersPgtoFinal = data.waitFinalPay
          this.infoBoxNumberOfCustomersPgtoComplete = data.complete
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    }
  }
}
</script>

<style>

</style>
