<template>
  <body
    id="mySidebar"
    class="
      sidebar-mini
      layout-fixed layout-navbar-fixed layout-footer-fixed
      sidebar-collapse
      text-sm
    "
    style="height: auto"
  >
    <div class="wrapper">
      <HeaderComponent />
      <SidebarComponent />

      <div
        class="modal fade"
        id="delete-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myModalLabel2">Remover registro</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Tenha certeza que você deseja remover o registro selecionado?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                @click="remove(dataToDelete)"
              >
                Remover
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="myWrapper" class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-6 col-sm-6">
                <h1>Deficiências</h1>
              </div>
              <div class="col-6 col-sm-6">
                <span class="float-sm-right add-button" v-if="render">
                  <router-link to="/add-def">
                    <button type="button" class="btn btn-block btn-success">
                      Cadastrar
                    </button>
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Lista de deficiências</h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body">
                  <LoaderComponent v-if="!render" />
                  <div
                    v-else
                    id="example2_wrapper"
                    class="dataTables_wrapper dt-bootstrap4"
                  >
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="col-12 overflow-x">
                          <table
                            class="
                              table table-bordered table-striped
                              dataTable
                              dtr-inline
                            "
                            role="grid"
                          >
                            <thead>
                              <tr role="row">
                                <th tabindex="0" rowspan="1" colspan="1">
                                  Tipo
                                </th>
                                <th tabindex="0" rowspan="1" colspan="1">
                                  Nome
                                </th>
                                <th tabindex="0" rowspan="1" colspan="1">
                                  Descrição
                                </th>
                                <th
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  class="operation-title"
                                >
                                  Operações
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                role="row"
                                v-for="data in defs"
                                :key="data.id"
                              >
                                <td nowrap>
                                  {{ transformDefType(data.type) }}
                                </td>
                                <td nowrap>{{ data.name }}</td>
                                <td nowrap>
                                  {{ data.description }}
                                </td>
                                <td class="operation-data">
                                  <span
                                    aria-label="Remover"
                                    data-balloon-pos="up"
                                    data-balloon-length="small"
                                    data-toggle="modal"
                                    data-target="#delete-modal"
                                    @click="dataToDelete = data.id"
                                  >
                                    <i class="fas fa-trash-alt danger-icon"></i>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="pagination-desc-footer" v-if="lastPage">
                          Total:
                          {{ Number(total).toLocaleString('pt-BR') }} registros
                        </div>
                        <div class="">
                          <paginate
                            v-if="lastPage"
                            v-model="page"
                            :page-count="lastPage"
                            :click-handler="getDataPage"
                            :prev-text="'Anterior'"
                            :next-text="'Próximo'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :active-class="'page-active'"
                            :margin-pages="2"
                            :page-range="3"
                          >
                          </paginate>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
            <!-- /.col -->
          </div>
        </section>
      </div>
      <div id="sidebar-overlay" @click="callCloseSidebar"></div>
    </div>
  </body>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import store from '../store/index'

import { prepareError } from '../utils/errorHandle.js'

import HeaderComponent from '@/components/HeaderComponent.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  name: 'users',
  components: {
    HeaderComponent,
    SidebarComponent,
    LoaderComponent
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: false,
      lastPage: 0,
      page: 1,
      total: 0,
      defs: [],
      dataToDelete: null
    }
  },
  created () {
    this.render = false
    this.getDataPage(1)
  },
  methods: {
    transformDefType (code) {
      const defMap = {
        1: 'Deficiente Físico',
        2: 'Deficiente Intelectual',
        3: 'Deficiente Visual',
        4: 'Autismo'
      }

      return defMap[code]
    },
    getDataPage (pageNum) {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN
      this.defs = []

      axios
        .get(this.api + `/def?page=${pageNum}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          this.render = true
          this.defs = response.data.data
          this.lastPage = response.data.lastPage
          this.page = pageNum
          this.total = response.data.total
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    },

    remove (id) {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN

      this.render = false
      axios
        .delete(`${this.api}/def/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        })
        .then((_) => {
          this.getDataPage(1)
          Vue.notify({
            title: 'Dados processados',
            text: 'Todos os dados foram processados com sucesso!',
            type: 'success'
          })
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })
    },

    callCloseSidebar () {
      this.$func()
    }
  }
}
</script>

<style scoped>
.add-button {
  text-decoration: none;
}

.ban-icon:hover {
  color: #dc3545;
}

.check-icon:hover {
  color: #28a745;
}

.overflow-x {
  overflow-x: auto;
}

.danger-icon {
  color: red;
}
</style>
