<template>
  <body id="mySidebar" class="sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed sidebar-collapse text-sm" style="height: auto;">
    <div class="wrapper">
      <HeaderComponent />
      <SidebarComponent />

      <div id="myWrapper" class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Dashboard</h1>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <LoaderComponent v-if="userType === null" />
          <div v-if="userType === 1">
            <AdminDashboard />
          </div>
          <div v-if="userType === 0">
            <UserDashboard />
          </div>
        </section>
      </div>
      <div id="sidebar-overlay" @click="callCloseSidebar"></div>
    </div>
  </body>
</template>

<script>
import store from '../store/index'

import HeaderComponent from '@/components/HeaderComponent.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

import AdminDashboard from '@/views/AdminDashboard.vue'
import UserDashboard from '@/views/UserDashboard.vue'

export default {
  name: 'dashboard',
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      token: store.getters.token || process.env.VUE_APP_ROOT_TOKEN,
      userType: null
    }
  },
  components: {
    HeaderComponent,
    SidebarComponent,
    AdminDashboard,
    UserDashboard,
    LoaderComponent
  },
  methods: {
    callCloseSidebar () {
      this.$func()
    }
  },
  created () {
    setTimeout(() => {
      this.userType = store.getters.signInUser.type // fake to user / admin is 1
    }, 1000)
  }
}
</script>

<style scoped>
.float-right {
  float: right;
}
</style>
