<template>
  <body
    id="mySidebar"
    class="
      sidebar-mini
      layout-fixed layout-navbar-fixed layout-footer-fixed
      sidebar-collapse
      text-sm
    "
    style="height: auto"
  >
    <div class="wrapper">
      <HeaderComponent />
      <SidebarComponent />

      <div id="myWrapper" class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-6 col-sm-6">
                <h1>Adicionar deficiência</h1>
              </div>
              <div class="col-6 col-sm-6">
                <span class="float-sm-right add-button" v-if="render">
                  <router-link :to="back">
                    <button
                      type="button"
                      class="btn btn-block btn-outline-secondary"
                    >
                      Voltar
                    </button>
                  </router-link>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <LoaderComponent v-if="!render" />

                  <div v-else>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label
                            >Tipo de deficiência
                            <span class="flag-required">*</span></label
                          >
                          <select
                            class="form-control"
                            name="type"
                            v-model="data.type"
                          >
                            <option disabled value="" selected>
                              Selecione uma opção
                            </option>
                            <option :value="1">Deficiente físico</option>
                            <option :value="2">Deficiente intelectual</option>
                            <option :value="3">Deficiente visual</option>
                            <option :value="4">Autismo</option>
                            <option :value="5">Deficiente auditivo</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label
                            >Nome da deficiência
                            <span class="flag-required">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            name="name"
                            v-model="data.name"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                          <label>Descrição</label>
                          <textarea
                            class="form-control"
                            rows="3"
                            name="description"
                            v-model="data.description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    :disabled="!data.name || !data.type"
                    @click="sendData()"
                  >
                    Salvar
                  </button>
                  &nbsp;
                  <router-link :to="back">
                    <button type="button" class="btn btn-outline-secondary">
                      Cancelar
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div id="sidebar-overlay" @click="callCloseSidebar"></div>
    </div>
  </body>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import store from '../store/index'

import { prepareError } from '../utils/errorHandle.js'

import HeaderComponent from '@/components/HeaderComponent.vue'
import SidebarComponent from '@/components/SidebarComponent.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
  name: 'AddDefs',
  components: {
    HeaderComponent,
    SidebarComponent,
    LoaderComponent
  },
  data () {
    return {
      api: process.env.VUE_APP_ROOT_API,
      render: true,
      back: '/defs',
      users: [],
      data: {
        name: '',
        type: '',
        description: ''
      }
    }
  },
  created () {},
  methods: {
    resetData () {
      this.data = {
        name: '',
        type: '',
        description: ''
      }
    },

    sendData () {
      const token = store.getters.token || process.env.VUE_APP_ROOT_TOKEN

      this.render = false
      axios({
        method: 'post',
        url: this.api + `/def`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        data: this.data
      })
        .then((_) => {
          this.$router.go(-1)

          Vue.notify({
            title: 'Dados processados',
            text: 'Todos os dados foram processados com sucesso!',
            type: 'success'
          })
        })
        .catch((error) => {
          this.render = true
          prepareError(error.response.status, this.$router, Vue.notify)
        })

      this.resetData()
    },
    callCloseSidebar () {
      this.$func()
    }
  }
}
</script>

<style scoped>
.add-button {
  text-decoration: none;
}

.ban-icon:hover {
  color: #dc3545;
}

.check-icon:hover {
  color: #28a745;
}

.overflow-x {
  overflow-x: auto;
}
</style>
